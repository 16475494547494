import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { InfoFilters } from '../../../components/InfoFilters';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import ReturnButton from '../../../components/ReturnButton';
import { SelectWithSearch } from '../../../components/SelectWithSearch';
import { SideMenu } from '../../../components/SideMenu';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { estados } from '../../../mocks/estados-mock';
import { laboratorios } from '../../../mocks/laboratorios-mock';
import {
  caraterBenigno,
  escamosa,
  glandular,
} from '../../../mocks/lesoes-mock';
import { municipios } from '../../../mocks/municipios-mock';
import { origemExame } from '../../../mocks/origem-exame';
import { Option } from '../../../types/Option';
import { pessoas } from '../../../mocks/pessoas-mock';
import { TableReportsHistopatologia } from '../../../components/TableReports/TableHistopatologia/TableReportsHistopatologia';
import FiltersReportsHistopatologia from './FiltersReportsHistopatologia';
import api from '../../../api';
import { getUserToken } from '../../../lib/auth';
import { getCidades } from '../../../lib/getCidadesEstabelecimentos';
import useGetDetailsFilters from '../ReportsHpv/FiltersReportsHpv/hooks/useGetDetailsFilters';
import useHandleChangeFilters from './FiltersReportsHistopatologia/hooks/useHandleChangesFilters';
import { DisplayFilter, ItemsDisplayFilter } from '../../Convocation/styles';
import { LoadingContext } from '../../../context/LoadingContext';

const RequiredText = () => {
  return (
    <div style={{ margin: '3px 0 0', fontSize: '12px' }}>
      <p style={{ margin: 0, fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' }}>
        <span style={{ color: 'red' }}>* </span>Obrigatório
      </p>
    </div>
  );
};

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'realizou_tratamento', value: '' },
  { inputName: 'dt_coleta_inicio', value: '' },
  { inputName: 'dt_coleta_fim', value: '' },
  { inputName: 'origem', value: '' },
  { inputName: 'lesoes_benigno', value: '' },
  { inputName: 'lesoes_neoplasico_escamosa', value: '' },
  { inputName: 'lesoes_neoplasico_glandular', value: '' },
  { inputName: 'laboratorio_responsavel_laudo', value: '' },
  { inputName: 'profissional_responsavel_resultado', value: '' },
  { inputName: 'dt_resultado_inicio', value: '' },
  { inputName: 'dt_resultado_fim', value: '' },
  { inputName: 'search', value: '' },
];

export const ReportsHistopatologia = () => {
  const [tableData, setTableData] = useState({
    data: [],
    page: 1,
    count: 25,
    totalPages: 1,
    totalItems: 1,
  });
  const [selectedHasLesao, setSelectedHasLesao] = useState<any>([]);

  const [profissional, setProfissional] = useState('');
  const [, setEstadosSelecionados] = useState<Option[]>([]);
  const [, setMunicipiosSelecionados] = useState<Option[]>([]);
  const [, setLaboratoriosSelecionados] = useState<Option[]>([]);
  const [periodColeta, setTipePeriodColeta] = useState('period');
  const [periodResult, setTipePeriodResult] = useState('period');
  const [periodDtColeta, setPeriodDtColeta] = useState('period');
  const [periodDtResultado, setPeriodDtResultado] = useState('period');
  const [showTableData, setShowTableData] = useState(false);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [selectedResultado, setSelectedResultado] = useState();
  const [dtColetaIni, setDataColetaIni] = useState<any>(null);
  const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);
  const [dtResultadoIni, setDataResultadoIni] = useState<any>(null);
  const [dtResultadoFinal, setDataResultadoFinal] = useState<any>(null);

  const [selectedOrigem, setSelectedOrigem] = useState([]);
  const [selectedCarater, setSelectedCarater] = useState([]);
  const [selectedEscamosa, setSelectedEscamosa] = useState([]);
  const [selectedGlandular, setSelectedGlandular] = useState([]);

  const [selectedLaboratorio, setSelectedLaboratorio] = useState<any>();

  const [selectedOptionsLaboratorio, setSelectedOptionsLaboratorio] = useState<
    any[]
  >([]);

  const [disabledProfissionalResultado, setDisabledProfissionalResultado] =
    useState(false);

  const [disabledLaboratorioResultado, setDisabledLaboratorioResultado] =
    useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,

      size: 25,
    },
  });

  const {
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    disableInputEstado,
    disableMunicipioInput,
    disableEstabelecimentoInput,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setDisableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setOptionsMunicipios,
  } = useStatesFields();

  const clearValuesInputs = () => {
    setEstabelecimentosSelected([]);
    setDataColetaFinal('');
    setDataColetaIni('');
    setDataResultadoIni('');
    setDataResultadoFinal('');
    setSelectedOrigem([]);
    setSelectedCarater([]);
    setSelectedEscamosa([]);
    setSelectedGlandular([]);
    setSelectedGlandular([]);

    setListFilter(initialStateListFilter);

    setValue('profissionalLaudoId', '');
  };

  const handleChange = (event) => {
    setProfissional(event.target.value);
  };

  const handleSetSelectedOptions = (setter) => (options) => {
    setter(options);
  };

  const handleSetPeriodColeta = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTipePeriodColeta((event.target as HTMLInputElement).value);
  };

  const handleSetPeriodResult = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTipePeriodResult((event.target as HTMLInputElement).value);
  };

  const { handleSetFilter } = useHandleChangeFilters({
    setListFilter,
  });

  const { getMunicipioDetails, getEstabelecimentoDetails, getAcsDetails } =
    useGetDetailsFilters();

  const getReportsResultadosHistopatologia = async (payloadToRequest) => {
    const response = await api.get('/reports/visualizar/histopatologia', {
      params: {
        ...payloadToRequest,
      },
    });
    setTableData(response.data);
    setLoading(false);
    if (response.data) {
      setLoading(false);
    }
  };

  const userToken = getUserToken();
  const isLaboratorio =
    getUserToken().permissao_atual.contexto === 'LABORATÓRIO';

  const setFiltersWhenPadrao = async (userToken) => {
    setShowTableData(true);
    setDisableInputEstado(true);
    setDisabledMunicipioInput(true);
    setDisabledEstabelecimentoInput(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      const optionCidade = [
        {
          id: userToken.endereco.municipio_id,

          nome: userToken.endereco.municipio_nome,
          codigo: userToken.endereco.municipio_code,
          estadoSigla: userToken.endereco.estado,
        },
      ];
      setOptionsMunicipios(optionCidade);

      if (!isLaboratorio) {
        payload = {
          page: 1,
          size: 25,
          estado: 'PE',
          municipioId: optionCidade[0].id,
          estabelecimentoSaudeId: userToken.estabelecimentoId,
        };

        setEstabelecimentosSelected([userToken.estabelecimentoId]);

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'estabelecimento_saude',
            value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
        ]);

        handleSetFilter('Estado: PE', 'estado');
        handleSetFilter(
          `Municipio: ${userToken.endereco.municipio_nome}`,
          'municipio'
        );
        handleSetFilter(
          `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
          'estabelecimento_saude'
        );
      } else {
        setSelectedLaboratorio([userToken.estabelecimentoId]);
        payload = {
          page: 1,
          size: 25,
          estado: 'PE',
          municipioId: optionCidade[0].id,
          laboratorioLaudoId: userToken.estabelecimentoId,
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel_laudo',
            value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
          {
            inputName: 'profissional_responsavel_resultado',
            value: `Profissional Responsável Pelo Resultado: ${userToken.pessoa.nome}`,
          },
        ]);

        handleSetFilter('Estado: PE', 'estado');
        handleSetFilter(
          `Municipio: ${userToken.endereco.municipio_nome}`,
          'municipio'
        );
        handleSetFilter(
          `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
          'laboratorio_responsavel_laudo'
        );
        handleSetFilter(
          `Profissional Responsável Pelo Resultado: ${userToken.estabelecimento_saude.nome_fantasia}`,
          'profissional_responsavel_resultado'
        );
      }

      setMunicipiosSelected([optionCidade[0].id]);

      resolve(payload);
    });
  };

  const setFiltersWhenMunicipal = async (userToken) => {
    setShowTableData(true);
    setDisableInputEstado(true);
    setDisabledMunicipioInput(true);
    setDisabledEstabelecimentoInput(false);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        payload = {
          page: 1,
          size: 25,
          estado: 'PE',
          municipioId: municipio[0].id,
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'estabelecimento_saude',
            value: ``,
          },
          { inputName: 'resultado', value: '' },
          { inputName: 'profissionalLaudoId', value: '' },
          { inputName: 'data_ini', value: '' },
          { inputName: 'data_fin', value: '' },
          { inputsetListFilterName: 'search', value: '' },
        ]);

        setListFilter([
          { inputName: 'estado', value: 'Estado: PE' },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'estabelecimento_saude',
            value: ``,
          },
          { inputName: 'resultado', value: '' },
          { inputName: 'profissionalLaudoId', value: '' },
          { inputName: 'data_ini', value: '' },
          { inputName: 'data_fin', value: '' },
          { inputName: 'search', value: '' },
        ]);
        resolve(payload);
      }
    });
  };

  const setFiltersWhenEstadual = async (userToken) => {
    setShowTableData(false);
    setDisableInputEstado(true);

    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
      };

      setListFilterShow([
        {
          inputName: 'estados',
          value: 'Estado: PE',
        },
      ]);

      handleSetFilter('Estado: PE', 'estados');
      // setLoading(false);

      // reject();
    });
  };

  const isMedicoLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 7;

  const isAdminLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.nome === 'Admin Estabelecimento';

  const isOutrosLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 8;

  const isUserLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO';

  const setFiltersWhenMedicoLaboratorio = () => {
    setDisableInputEstado(true);
    setDisabledLaboratorioResultado(true);
    setDisabledProfissionalResultado(true);
    setShowTableData(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      setOptionsMunicipios(optionsCidades);

      // setEstabelecimentosSelected([userToken.estabelecimentoId]);

      const getProfissional = await api.get(
        `/profissionais/estabelecimento/${userToken.estabelecimentoId}`
      );

      const dataOffProfissional = getProfissional.data?.find(
        (prof) => prof.idpessoa === userToken.pessoa.id
      );

      setValue('profissional_responsavel_resultado', {
        ...dataOffProfissional,
        label: dataOffProfissional.nomeprofissional,
      });

      const labs = [
        {
          id: 1474,
          nome_fantasia: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
          cnes: 2712105,
          label: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
        },
        {
          id: 4691,
          nome_fantasia: 'US 376 POLICLINICA SALOMAO KELNER',
          cnes: 6897029,
          label: 'US 376 POLICLINICA SALOMAO KELNER',
        },
        {
          id: 158,
          nome_fantasia: 'US 144 POLICLINICA CLEMENTINO FRAGA',
          cnes: '0000647',
          label: 'US 376 POLICLINICA SALOMAO KELNER',
        },
        {
          id: 61,
          nome_fantasia: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
          cnes: '0000779',
          label: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
        },
      ] as any;

      setSelectedOptionsLaboratorio(
        labs.filter((lab) => lab.id === userToken.estabelecimentoId)
      );
      setSelectedLaboratorio([
        {
          label: userToken.estabelecimento_saude.nome_fantasia,
          value: userToken.estabelecimentoId,
        },
      ]);

      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
        profissionalLaudoId: dataOffProfissional?.idprofissional,
        laboratorioLaudoId: userToken.estabelecimentoId,
      };

      setListFilterShow([
        {
          inputName: 'estado',
          value: 'Estado: PE',
        },
        {
          inputName: 'municipio',
          value: ``,
        },
        {
          inputName: 'laboratorio_responsavel',
          value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
        },

        {
          inputName: 'profissional_responsavel',
          value: `Profissional Responsável pelo Resultado: ${userToken?.pessoa?.nome}`,
        },
      ]);

      setListFilter([
        { inputName: 'estado', value: 'Estado: PE' },
        {
          inputName: 'municipio',
          value: ``,
        },
        {
          inputName: 'laboratorio_responsavel',
          value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
        },
        {
          inputName: 'profissional_responsavel',
          value: `Profissional Responsável pelo Laudo: ${dataOffProfissional?.pessoa?.nome}`,
        },
        { inputName: 'resultado', value: '' },
        { inputName: 'profissionalLaudo', value: '' },
        { inputName: 'data_ini', value: '' },
        { inputName: 'data_fin', value: '' },
        { inputName: 'search', value: '' },
      ]);
      resolve(payload);
    });
  };

  const setFiltersWhenAdminOrOthersLaboratorio = () => {
    setDisableInputEstado(true);
    setDisabledLaboratorioResultado(true);
    setShowTableData(false);

    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        // setEstabelecimentosSelected([userToken.estabelecimentoId]);

        const labs = [
          {
            id: 1474,
            nome_fantasia: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
            cnes: 2712105,
            label: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
          },
          {
            id: 4691,
            nome_fantasia: 'US 376 POLICLINICA SALOMAO KELNER',
            cnes: 6897029,
            label: 'US 376 POLICLINICA SALOMAO KELNER',
          },
          {
            id: 158,
            nome_fantasia: 'US 144 POLICLINICA CLEMENTINO FRAGA',
            cnes: '0000647',
            label: 'US 376 POLICLINICA SALOMAO KELNER',
          },
          {
            id: 61,
            nome_fantasia: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
            cnes: '0000779',
            label: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
          },
        ] as any;

        const labFromUser = labs.find(
          (lab) => lab.id === userToken.estabelecimentoId
        );

        setSelectedOptionsLaboratorio(
          labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        );
        setSelectedLaboratorio([
          { value: labFromUser.id, label: labFromUser.nome },
        ]);

        payload = {
          page: 1,
          size: 25,
          estados: 'PE',
          laboratorioId: userToken.estabelecimentoId,
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
        ]);

        setListFilter([
          { inputName: 'estado', value: 'Estado: PE' },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Laboratório Responsável pelo Laudo: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
          { inputName: 'resultado', value: '' },
          { inputName: 'profissional_responsavel', value: '' },
          { inputName: 'profissionalLaudo', value: '' },
          { inputName: 'data_ini', value: '' },
          { inputName: 'data_fin', value: '' },
          { inputName: 'search', value: '' },
        ]);
        resolve(payload);
      }
    });
  };

  const requestDataByUserPerfil = async () => {
    if (
      userToken.permissao_atual.id === 1 ||
      userToken.permissao_atual.id === 6 ||
      userToken.permissao_atual.id === 7 ||
      userToken.permissao_atual.id === 8 ||
      userToken.permissao_atual.id === 9 ||
      userToken.permissao_atual.id === 10
    ) {
      if (!isUserLaboratorio) {
        setFiltersWhenPadrao(userToken).then((payload) => {
          getReportsResultadosHistopatologia(payload);
        });
      } else {
        setDisabledLaboratorioResultado(true);
        if (isMedicoLaboratorio) {
          setFiltersWhenMedicoLaboratorio().then((payload) => {
            getReportsResultadosHistopatologia(payload);
          });
        }

        if (isAdminLaboratorio || isOutrosLaboratorio) {
          setFiltersWhenAdminOrOthersLaboratorio();
          setLoading(true);
        }
      }
    }

    if (userToken.permissao_atual.id === 3) {
      setFiltersWhenMunicipal(userToken).then((payload) => {
        getReportsResultadosHistopatologia(payload);
      });
    }

    if (userToken.permissao_atual.id === 4) {
      setFiltersWhenEstadual(userToken);
      setLoading(true);
    }

    if (userToken.permissao_atual.id === 2) {
      setLoading(false);
    }

    return true;
  };

  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    // if (origin === 'tables') {
    //   setLoading(true);
    //   setFiltersToRequestByTable().then((payloadToRequest) => {
    //     getReportsTracking(payloadToRequest);
    //     setUsePayloadFromCharts(true);
    //   });

    //   window.history.replaceState({}, '');
    // } else {
    //   setLoading(true);
    //   requestDataByUserPerfil();
    // }
    requestDataByUserPerfil();
  }, [origin]);

  useEffect(() => {
    if (estadosSelected.length === 1) {
      setDisabledMunicipioInput(false);
    } else {
      setDisabledMunicipioInput(true);
    }
  }, [estadosSelected]);

  useEffect(() => {
    if (municipiosSelected.length === 1) {
      setDisabledEstabelecimentoInput(false);
    } else {
      setDisabledEstabelecimentoInput(true);
    }
  }, [municipiosSelected]);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Relatório Geral de Exames de Histopatologia
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography color="text.primary">Relatórios</Typography>

                <Typography key="3" color="text.primary">
                  Relatório Geral de Exames de Histopatologia
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              // fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />

            <DisplayFilter style={{ marginTop: '24px' }}>
              {!listFilterShow?.find((item) => item.value !== '') && (
                <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                  Os filtros selecionados aparecerão aqui.
                </p>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {listFilterShow.map((item) => (
                  <>
                    {item.value ? (
                      <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                    ) : undefined}
                  </>
                ))}
              </div>
            </DisplayFilter>

            <FiltersReportsHistopatologia
              selectedHasLesao={selectedHasLesao}
              setSelectedHasLesao={setSelectedHasLesao}
              errors={errors}
              clearErrors={clearErrors}
              setDisabledLaboratorioResultado={setDisabledLaboratorioResultado}
              setSelectedLaboratorio={setSelectedLaboratorio}
              selectedLaboratorio={selectedLaboratorio}
              disabledLaboratorioResultado={disabledLaboratorioResultado}
              disabledProfissionalResultado={disabledProfissionalResultado}
              selectedOptionsLaboratorio={selectedOptionsLaboratorio}
              setSelectedOptionsLaboratorio={setSelectedOptionsLaboratorio}
              disableMunicipioInput={disableMunicipioInput}
              disableEstabelecimentoInput={disableEstabelecimentoInput}
              disableInputEstado={disableInputEstado}
              clearValuesInputs={clearValuesInputs}
              selectedGlandular={selectedGlandular}
              selectedEscamosa={selectedEscamosa}
              selectedCarater={selectedCarater}
              selectedOrigem={selectedOrigem}
              setSelectedCarater={setSelectedCarater}
              setSelectedEscamosa={setSelectedEscamosa}
              setSelectedGlandular={setSelectedGlandular}
              setSelectedOrigem={setSelectedOrigem}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              setDataResultadoIni={setDataResultadoIni}
              setDataResultadoFinal={setDataResultadoFinal}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              setTableData={setTableData}
              setShowTableData={setShowTableData}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              periodDtColeta={periodDtColeta}
              periodDtResultado={periodDtResultado}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              setSelectedResultado={setSelectedResultado}
              setPeriodDtColeta={setPeriodDtColeta}
              setPeriodDtResultado={setPeriodDtResultado}
              setDataColetaFinal={setDataColetaFinal}
              setDataColetaIni={setDataColetaIni}
              dtColetaIni={dtColetaIni}
              dtColetaFinal={dtColetaFinal}
              selectedResultado={selectedResultado}
              watch={watch}
              setError={setError}
              showTableData={showTableData}
              listFilter={listFilter}
              setListFilter={setListFilter}
              setListFilterShow={setListFilterShow}
            />
            <TableReportsHistopatologia
              selectedResultado={selectedResultado}
              dtColetaFinal={dtColetaFinal}
              dtColetaIni={dtColetaIni}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              selectedGlandular={selectedGlandular}
              selectedEscamosa={selectedEscamosa}
              selectedCarater={selectedCarater}
              selectedOrigem={selectedOrigem}
              showTableData={showTableData}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              selectedHasLesao={selectedHasLesao}
              setSelectedHasLesao={setSelectedHasLesao}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};

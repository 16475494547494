import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../../components/StyledAsteriskTextField/styles';
import { useStyles } from '../../RegisterPatient.style';
import { useRequests } from '../../hooks/useRequests';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { getUserToken } from '../../../../lib/auth';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../api';

const BindInformation = ({
  errors,
  control,
  setValue,
  estados,
  watch,
  haventVinculo,
  sethaventVinculo,
  setDisableInputsVinculo,
  clearErrors,
  setEstabelecimentoVinculo,
  disableInputsVinculo,
  setEstabelecimentosSelect,
  estabelecimentosSelect,
  setProfissionalNotHasEquipe,
}) => {
  const [equipesOptions, setEquipesOptions] = useState<any>();
  const [cidadesEstabelecimentos, setCidadesEstabelecimentos] =
    useState<any[]>();
  const [openModalSelectEquipe, setOpenModalSelectEquipe] = useState(false);
  const [equipesList, setEquipesList] = useState([]);
  const [professionalSearch, setProfessionalSearch] = useState('');
  const [options, setOptions] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [disableEquipeNome, setDisableEquipeNome] = useState(true);

  const styles = useStyles();
  const prof = watch('vinculo.profissionalNome');
  const equipeNome = watch('vinculo.equipeNome');
  const equipeIne = watch('vinculo.equipeIne');

  const location = useLocation();

  const notSeeAccesses = ['ZONE_ESTADO', 'ZONE_PAIS', 'ZONE_ALL']
  const seeAllPermission = getUserToken()?.permissoes?.find(p => notSeeAccesses.includes(p))
  console.log(seeAllPermission)

  const {
    getAcsByName,
    getCidadesEstabelecimentos,
    getEstabelecimentosByMunicipio,
  } = useRequests({
    setValue,
  });


  useEffect(() => {
    if(!professionalSearch && prof?.length || 0 > 3){
      setProfessionalSearch(prof);
    }
    /*if(professionalSearch.trim().length < 3) {
      //clear
      setOptions([]);
      setLoading(false);
      return
    };*/
    setLoading(true);
    const timeoutId = setTimeout(async () => {
      try {
        
        const response = await api.get(`/profissionais/acs-list/${professionalSearch||'undefined'}`);
        setOptions(response?.data?.map(data => {
          return {
            label: data.profissional.pessoa.nome,
            value: data.profissional.id,
            equipe: data.equipes?.[0],
            profissional: data.profissional,
            estabelecimento: data.profissional.estabelecimentos?.[0]?.estabelecimento
          }
        }))
        setLoading(false)
      } catch(err) {
        console.error(err)
        setOptions([]);
        setLoading(false)
      }
      
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [professionalSearch, prof])


  function clear() {
    setValue('vinculo.profissionalCns', '');
    setValue('vinculo.profissionalNome', '');
    setValue('vinculo.profissionalId', '');
    setValue('vinculo.cbo', '');
    setValue('vinculo.tipo', '');
    setValue('vinculo.equipeNome', '');
    setValue('vinculo.equipeIne', null);
    setValue('vinculo.area', null);
    setValue('vinculo.micro_area', null);
    setValue('vinculo.uf', '');
    setValue('vinculo.municipio', '');
    setValue('vinculo.estabelecimento_saude', '');

    clearErrors('vinculo.profissionalCns');
    clearErrors('vinculo.profissionalNome');
    clearErrors('vinculo.cbo');
    clearErrors('vinculo.tipo');
    clearErrors('vinculo.equipeNome');
    clearErrors('vinculo.equipeIne');
    clearErrors('vinculo.area');
    clearErrors('vinculo.micro_area');
    clearErrors('vinculo.uf');
    clearErrors('vinculo.municipio');
    clearErrors('vinculo.estabelecimento_saude');
  }

  return (
    <>
      <Dialog
        open={openModalSelectEquipe}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        }}
      >
        <Box>
          <h1>Selecione uma equipe</h1>
          <Autocomplete
            options={equipesList}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                placeholder="Selecione uma equipe"
              />
            )}
          />
        </Box>
      </Dialog>
      <Box sx={{ width: '100%', marginTop: '24px' }}>
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            border: `1px solid RGBA(0, 0, 0, 0.23)`,
            borderRadius: '10px',
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            style={{
              margin: '25px',
              paddingTop: '10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Dados de Vinculação - Agente Comunitário de Saúde

            <Controller
              control={control}
              name="vinculo.active"
              defaultValue={haventVinculo}
              render={({ field: { onChange, value } }) => {
              return <FormControlLabel
                control={
                <Checkbox
                value={value}
                checked={value}
                onClick={(v: any) => {
                  sethaventVinculo(v.target?.checked);
                  setDisableInputsVinculo(v.target?.checked);
                  onChange(v.target?.checked)
                  clear()
                }}

                />}
                label="Não possui vinculação"
              />
            }}
          />
          </Typography>
          <Divider variant="middle" sx={{ width: '96%', marginLeft: '2%' }} />
          <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}
            >
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="vinculo.profissionalNome"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                      disablePortal
                      options={options.sort((a, b) => -b?.estabelecimento.nome_fantasia.localeCompare(a?.estabelecimento?.nome_fantasia))}
                      loading={loading}
                      loadingText={professionalSearch?.length < 3 ? seeAllPermission ? 'Digite 3 carateres' : 'Carregando...' : 'Carregando...'}
                      noOptionsText={professionalSearch?.length < 3 ? seeAllPermission ? 'Digite 3 carateres' : 'Sem Resultados.' : 'Sem Resultados'}
                      onChange={async (event, data) => {
                        if(!data) {
                          clear()
                          setEstabelecimentoVinculo(undefined)
                          setProfessionalSearch('undefined')
                          setDisableEquipeNome(true)
                          return
                        }
                        setValue('vinculo.profissionalId', data?.value)
                        setValue('vinculo.profissionalNome', data.label)
                        setValue('vinculo.profissionalCns', data.profissional.cns);
                        setValue('vinculo.profissionalNome', data.label);
                        setValue('vinculo.cbo', data.profissional.cbo);
                        setValue('vinculo.tipo', data.profissional.cargo);
                        setValue('vinculo.equipeNome', data.equipe?.equipe?.nome);
                        setValue('vinculo.equipeIne', data.equipe?.equipe?.ine);
                        setValue('vinculo.area', data.equipe?.area);
                        setValue('vinculo.micro_area', data.equipe?.micro_area);
                        setValue('vinculo.uf', data.estabelecimento.endereco?.municipio.estadoSigla);
                        setValue('vinculo.municipio', data.estabelecimento.endereco?.municipio.nome);
                        
                        setValue('vinculo.estabelecimentoString', data.estabelecimento.nome_fantasia);
                        setValue('vinculo.estabelecimento_saude', data.estabelecimento);
                        setEstabelecimentoVinculo(data.estabelecimento?.id)
                        setDisableEquipeNome(!!data.equipe?.equipe?.nome)
                      }}
                      groupBy={(option) => option?.estabelecimento?.nome_fantasia}
                      getOptionLabel={(option) => option?.label}
                      size="small"
                      renderGroup={(params) => {
                        return (
                          <li key={params.key}>
                            <Typography fontSize={12} marginLeft={1} color={'#555'} marginTop={2} position='-webkit-sticky'>
                              {params.group}
                            </Typography>
                            {params.children}
                          </li>
                        );
                      }}
                      value={{label: prof||''}}
                      renderInput={(params) => <TextField 
                        {...params} 
                        onChange={(v) => {
                          onChange(v)
                          setProfessionalSearch(v?.target?.value || '')
                          if(!v?.target?.value) clear()
                        }}
                        fullWidth
                        label={<>Nome do Profissional <span style={{color: 'red'}}>*</span></>}
                        value={value ? value : ''}
                        error={!!errors?.vinculo?.profissionalNome}
                        helperText={errors?.vinculo?.profissionalNome?.message}
                        disabled={haventVinculo}
                      />}
                    />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="vinculo.profissionalCns"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        label="CNS do Profissional"
                        fullWidth
                        onChange={onChange}
                        disabled={true}
                        error={!!errors?.vinculo?.profissionalCns}
                        helperText={errors?.vinculo?.profissionalCns?.message}
                        // required={!haventVinculo}
                        size="small"
                        value={value ? value : ''}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="vinculo.cbo"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        onChange={onChange}
                        label="CBO do Profissional"
                        error={!!errors?.vinculo?.cbo}
                        helperText={errors?.vinculo?.cbo?.message}
                        fullWidth
                        // required={!haventVinculo}
                        disabled={true}
                        size="small"
                        value={value ? value : ''}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="vinculo.tipo"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        error={!!errors?.vinculo?.tipo}
                        onChange={onChange}
                        helperText={errors?.vinculo?.tipo?.message}
                        label="Tipo de Profissional"
                        disabled={true}
                        fullWidth
                        // required={!haventVinculo}
                        size="small"
                        value={value ? value : ''}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="vinculo.equipeNome"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        {equipesOptions?.length > 1 ? (
                          <Autocomplete
                            options={equipesOptions}
                            getOptionLabel={(option: any) =>
                              option.equipe?.nome
                            }
                            onChange={(e, newValue) => {
                              onChange(newValue);
                              setValue(
                                'vinculo.equipeIne',
                                newValue?.equipe?.ine
                              );
                              setValue(
                                'vinculo.equipeNome',
                                newValue?.equipe?.nome
                              );
                              setValue('vinculo.area', newValue?.area);
                              setValue(
                                'vinculo.micro_area',
                                newValue?.micro_area
                              );
                            }}
                            renderInput={(params) => (
                              <StyledAsteriskTextField
                                {...params}
                                // error={!!errors?.vinculo?.equipeNome}
                                label="Nome da Equipe"
                                fullWidth
                                size="small"
                                required={disableInputsVinculo || equipeNome}
                                // required={!haventVinculo}
                              />
                            )}
                          />
                        ) : (
                          <StyledAsteriskTextField
                            error={!!errors?.vinculo?.equipeNome}
                            helperText={errors?.vinculo?.equipeNome?.message}
                            value={value ? value : ''}
                            label="Nome da Equipe"
                            fullWidth
                            onChange={(e) => onChange(e.target?.value)}
                            // required={!haventVinculo}
                            disabled={disableEquipeNome}
                            size="small"
                          />
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="vinculo.equipeIne"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        label="INE da Equipe"
                        fullWidth
                        size="small"
                        disabled={disableEquipeNome ? disableInputsVinculo || equipeIne : false}
                        value={value ? value : ''}
                        onChange={(e) => {
                          onChange(e.target?.value);
                          clearErrors('vinculo.equipeIne');
                        }}
                        error={!!errors.vinculo?.equipeIne}
                        helperText={errors?.vinculo?.equipeIne?.message}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="vinculo.area"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        onChange={onChange}
                        error={!!errors?.vinculo?.area}
                        helperText={errors?.vinculo?.area?.message}
                        label="Área"
                        fullWidth
                        // required={!haventVinculo}
                        disabled={disableInputsVinculo}
                        size="small"
                        value={value ? value : ''}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="vinculo.micro_area"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        label="Microárea"
                        onChange={onChange}
                        // required={!haventVinculo}
                        disabled={haventVinculo}
                        error={!!errors.vinculo?.micro_area}
                        helperText={errors.vinculo?.micro_area?.message}
                        fullWidth
                        size="small"
                        value={value ? value : ''}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Controller
                  control={control}
                  name="vinculo.uf"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        label="UF"
                        select={!disableInputsVinculo}
                        disabled={true}
                        fullWidth
                        // required={!haventVinculo}
                        value={value ? value : ''}
                        size="small"
                        variant="outlined"
                        className={styles.textField}
                        onChange={(e) => {
                          onChange(e.target?.value);
                          getCidadesEstabelecimentos(
                            e.target?.value,
                            setCidadesEstabelecimentos
                          );
                        }}
                        defaultValue={() => {
                          const estado =
                            location?.state?.estabelecimento_saude?.endereco
                              ?.municipio?.estadoSigla ||
                            (!location?.state &&
                              !location.state &&
                              getUserToken().endereco?.estado) ||
                            '';

                          estado &&
                            getCidadesEstabelecimentos(
                              estado || '',
                              setCidadesEstabelecimentos
                            );

                          return estado;
                        }}
                        error={!!errors.vinculo?.uf}
                        helperText={errors.vinculo?.uf?.message}
                      >
                        {estados?.map((estado, index) => {
                          return (
                            <MenuItem value={estado?.sigla} key={index}>
                              {estado?.sigla}
                            </MenuItem>
                          );
                        })}
                      </StyledAsteriskTextField>
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  control={control}
                  name="vinculo.municipio"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <StyledAsteriskTextField
                        fullWidth
                        label="Município"
                        size="small"
                        disabled={true}
                        variant="outlined"
                        // required={!haventVinculo}
                        value={value ? value : ''}
                        className={styles.textField}
                        select={false}
                        onChange={(e) => {
                          onChange(e.target?.value);
                          getEstabelecimentosByMunicipio(
                            e.target?.value,
                            setEstabelecimentosSelect
                          );
                        }}
                        defaultValue={() => {
                          const municipio =
                            location?.state?.estabelecimento_saude?.endereco
                              ?.municipio?.nome ||
                            (!location.state &&
                              getUserToken().endereco?.municipio_nome) ||
                            '';

                          municipio &&
                            getEstabelecimentosByMunicipio(
                              municipio || '',
                              setEstabelecimentosSelect
                            );

                          return municipio;
                        }}
                        error={!!errors.vinculo?.municipio}
                        helperText={errors.vinculo?.municipio?.message}
                        // error={!!errors.endereco?.municipio?.id}
                      >
                        {cidadesEstabelecimentos?.map((item) => {
                          return (
                            <MenuItem value={item.nome} key={item.nome}>{item.nome}</MenuItem>
                          );
                        })}
                      </StyledAsteriskTextField>
                    );
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  marginBottom: errors.estabelecimento_saude ? '-25px' : '',
                }}
              >
                <Controller
                  name={'vinculo.estabelecimento_saude'}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        <Autocomplete
                          placeholder="Estabelecimento de Saúde"
                          value={value ? value : null}
                          onChange={(_, newValue) => {
                            onChange(newValue ? newValue : {});
                            setEstabelecimentoVinculo(
                              newValue ? newValue.id : null
                            );
                          }}
                          disabled={true}
                          options={
                            estabelecimentosSelect
                              ? estabelecimentosSelect
                              : [{ nome_fantasia: 'Não há opções' }]
                          }
                          getOptionLabel={(option) => option.nome_fantasia}
                          renderInput={(params) => (
                            <StyledAsteriskTextField
                              {...params}
                              // required
                              fullWidth
                              size="small"
                              label="Estabelecimento de Saúde"
                              variant="outlined"
                              className={styles.textField}
                              error={!!errors.estabelecimento_saude}
                              helperText={errors.estabelecimento_saude?.message}
                            />
                          )}
                        />
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default BindInformation;

/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TablePatients from '../../components/TablePatients/TablePatients';
import { useStyles } from './ListPatients.style';
import { PeopleContext } from '../../context/PeopleContext';
import { SideMenu } from '../../components/SideMenu';
import ReturnButton from '../../components/ReturnButton';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { validarCPF } from '../../utils/validateCPF';
import AlertInfo from '../../assets/imgs/AlertInfo.svg';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { isDate } from 'date-fns';

export default function ListPatients() {
  const styles = useStyles();
  const { people, setFilter } = React.useContext(PeopleContext);
  const [loading, setLoading] = React.useState(false);
  const [nSus, setNSus] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [dtNascimento, setDtNascimento] = React.useState<any>(null);
  const [cpf, setCpf] = React.useState('');
  const [nomeMae, setnomeMae] = React.useState('');
  const [errors, setErrors] = React.useState<any>({});
  const [showTable, setShowTable] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    if (people) {
      setLoading(false);
    }
  }, [people]);

  const handleSearch = () => {
    const values = {
      cns: nSus,
      nome,
      dt_nascimento: encodeURI(
        dtNascimento?.toLocaleDateString().replace(/\//g, '-') || ''
      ),
      cpf: cpf.replace(/[.-]/g, ''),
      nome_mae: nomeMae,
    };

    setShowTable(true);
    setFilter(values);
  };

  const validateCpfWhenUserTypeElevenNumbers = () => {
    const cpfIsValid = validarCPF(cpf);
    const CpfWithoutChar = cpf.replace(/[-.]/g, '');

    const setOrClearErrorsOnCpfInput = () => {
      cpfIsValid === false ? setErrors({ cpf: 'CPF inválido' }) : setErrors({});
    };

    if (CpfWithoutChar.length === 11) setOrClearErrorsOnCpfInput();
  };

  const handleClear = () => {
    setNSus('');
    setNome('');
    setDtNascimento(null);
    setCpf('');
    setnomeMae('');
    setShowTable(false);
  };

  React.useEffect(() => {
    if (cpf) {
      validateCpfWhenUserTypeElevenNumbers();
    } else {
      setErrors({});
    }
  }, [cpf]);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/home" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="h4"
                fontWeight="bold"
                style={{ marginBottom: '30px' }}
                data-testid="pacientesList"
              >
                {`${showTable ? 'Listagem das' : 'Busca por'} Pacientes`}
                <Tooltip
                  title="Para pesquisar paciente, recomendamos utilizar no máximo 2 filtros"
                  placement="top"
                >
                  <img src={AlertInfo} alt="" style={{ marginLeft: '13px' }} />
                </Tooltip>
              </Typography>
            </div>
            <Divider />
            <Box mt={4} mb={4}>
              <Grid
                container
                spacing={2}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Grid item xs={12} lg={2.4}>
                  <StyledAsteriskTextField
                    //required
                    onChange={(e) => {
                      const cpf = e.target.value.replace(/_/g, ''); // Remover os "_"
                      setCpf(cpf);
                    }}
                    value={cpf ? cpf : ''}
                    label="CPF"
                    size="small"
                    variant="outlined"
                    className={styles.textField}
                    error={!!errors?.cpf}
                    helperText={errors?.cpf}
                    sx={{ width: '100%' }}
                    InputProps={{
                      autoComplete: 'off',
                      inputComponent: MaskedInput as any,
                      inputProps: {
                        'data-testid': 'cpfField',
                        mask: [
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                        ],
                      } as MaskedInputProps,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={2.4}>
                  <TextField
                    label="Nº do Cartão SUS"
                    size="small"
                    inputProps={{
                      'data-testid': 'cnsField',
                    }}
                    variant="outlined"
                    className={styles.textField}
                    value={nSus}
                    onChange={(e) => {
                      if (e.target.value.length < 16) {
                        setNSus(e.target.value.replace(/\D+/g, ''));
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={2.4}>
                  <TextField
                    label="Nome"
                    size="small"
                    variant="outlined"
                    inputProps={{
                      'data-testid': 'nameField',
                    }}
                    className={styles.textField}
                    value={nome}
                    onChange={({ target }) => setNome(target.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={2.4}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <DatePicker
                      onChange={(v: any) => {
                        setDtNascimento(v);
                      }}
                      value={dtNascimento ? dtNascimento : null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.dtNascimento}
                          label="Data de nascimento"
                          size="small"
                          variant="outlined"
                          className={styles.textField}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} lg={2.4}>
                  <TextField
                    label="Nome da mãe"
                    size="small"
                    variant="outlined"
                    inputProps={{
                      'data-testid': 'nameMotherField',
                    }}
                    className={styles.textField}
                    value={nomeMae}
                    onChange={({ target }) => setnomeMae(target.value)}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  gap={2}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid item xs={12} lg={1.5}>
                    <Button
                      variant="outlined"
                      startIcon={<SearchIcon />}
                      data-testid="filterButton"
                      style={{
                        backgroundColor: '#238884',
                        color: '#FFFFFF',
                        //height: '100%',
                        width: '100%',
                      }}
                      onClick={handleSearch}
                    >
                      FILTRAR
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={1.5}>
                    <Button
                      sx={{ fontWeight: 'bold', width: '100%' }}
                      variant="outlined"
                      data-testid="cleanButton"
                      color="error"
                      onClick={handleClear}
                    >
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {showTable && <TablePatients {...people} />}
          </Box>
        </Container>
      </SideMenu>
    );
  }
}

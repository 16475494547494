import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { Escolha, Motivo } from '../RegisterExam';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { ptBR } from 'date-fns/locale';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import api from '../../../api';
import moment from 'moment';

const AnmamneseForm = ({
  errors,
  control,
  watch,
  styles,
  checkedMenstruacao,
  setCheckedMenstruacao,
  clearErrors,
  setValue,
  screenMode,
  idPatient,
  exam,
}: any) => {
  const [lastHpvColeta, setLastHpvColeta] = useState<any>();
  const [lastCitologia, setLastCitologia] = useState<any>();

  const rhf = watch();
  const styleRadio = {
    '&.Mui-checked': {
      color: '#238884',
    },
  };

  const styleFormLabel = {
    '&.Mui-focused': {
      color: '#238884',
    },

    '& .MuiFormLabel-asterisk': {
      color: '#d32f2f',
    },
  };

  const getDataLastHpv = async () => {
    try {
      if (screenMode === 'view') {
        const id = exam.last_hpv_id || exam.anamnese.last_hpv_id;
        const response = await api.get(`/hpv/${id}`);
        if (response?.data) {
          setLastHpvColeta(response.data ? response.data : null);
        }
        return;
      }
      const response = await api.get(`/hpv/lastHpv/${idPatient}`);

      setLastHpvColeta(response.data ? response.data : null);
    } catch (err) {}
  };

  const getDataLastCitologia = async () => {
    try {
      if (screenMode === 'view') {
        const id = exam.last_citologia_id || exam.anamnese.last_citologia_id;
        const response = await api.get(`/citologia/carga/${id}`);
        if (response?.data) {
          setLastCitologia(response.data ? response.data : null);
        }
        return;
      }
      const response = await api.get(`/citologia/carga/last/${idPatient}`);

      setLastCitologia(response.data ? response.data : null);
    } catch (err) {}
  };

  useEffect(() => {
    getDataLastHpv();
    getDataLastCitologia();

    if (screenMode === 'view' && !exam) {
      console.error("Envie os dados do exame com a anamnese em 'exam'");
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('anamnese.dt_menstruacao', '');
    }
    setValue('anamnese.dt_menstruacao_desconhecida', event.target.checked);
    setCheckedMenstruacao(event.target.checked);
    clearErrors('anamnese.dt_menstruacao');
  };

  const menopausaField = watch('anamnese.menopausa');

  useEffect(() => {
    if (menopausaField === 'NAO' || menopausaField === 'NAO_SABE') {
      setValue('anamnese.hormonio', null);
      setValue('anamnese.sangramento_menopausa', null);
    }
  }, [menopausaField]);

  const hpvField = watch('anamnese.hpv');
  const papanicolaouField = watch('anamnese.papanicolaou');
  useEffect(() => {
    if (hpvField === 'NAO' || hpvField === 'NAO_SABE') {
      setValue('anamnese.ano_hpv', null);
    }
    if (papanicolaouField === 'NAO' || papanicolaouField === 'NAO_SABE') {
      setValue('anamnese.ano_papanicolaou', undefined);
    }
  }, [hpvField, papanicolaouField]);

  return (
    <Box>
      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          style={{ margin: '25px', paddingTop: '25px' }}
        >
          Dados da Anamnese
        </Typography>
        <Divider />
        <Box m={3} pb={3} sx={{ flexDirection: 'column' }}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <StyledAsteriskTextField
                    disabled={screenMode === 'view'}
                    select
                    required
                    onChange={onChange}
                    value={value ? value : ''}
                    fullWidth
                    label="Motivo do exame"
                    size="small"
                    variant="outlined"
                    error={!!errors.anamnese?.motivo}
                    helperText={errors.anamnese?.motivo?.message}
                    className={styles.textField}
                  >
                    <MenuItem value={Motivo.Rastreamento}>
                      Rastreamento
                    </MenuItem>

                    <MenuItem value={Motivo.Repeticao}>
                      Seguimento (controle pós-teste HPV positivo)
                    </MenuItem>
                    <MenuItem value={Motivo.Seguimento}>
                      Seguimento (pós-tratamento)
                    </MenuItem>
                  </StyledAsteriskTextField>
                )}
                name="anamnese.motivo"
                control={control}
              />
            </Grid>

            <Grid item xs={12} lg={4.3}>
              <FormControl error={errors.anamnese?.papanicolaou && true}>
                <FormLabel sx={styleFormLabel} required>
                  Fez o exame preventivo (Papanicolaou) alguma vez?
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                    >
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Sim}
                        control={<Radio sx={styleRadio} />}
                        label="Sim"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Nao}
                        control={<Radio sx={styleRadio} />}
                        label="Não"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.NaoSabe}
                        control={<Radio sx={styleRadio} />}
                        label="Não Sabe"
                      />
                    </RadioGroup>
                  )}
                  name="anamnese.papanicolaou"
                  control={control}
                />
                {errors.anamnese?.papanicolaou?.message && (
                  <FormHelperText>
                    {errors.anamnese?.papanicolaou?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {rhf.anamnese?.papanicolaou === Escolha.Sim ? (
              <Grid item sx={{ mt: 2 }} xs={12} lg={3.9}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <StyledAsteriskTextField
                      fullWidth
                      disabled={screenMode === 'view'}
                      onChange={(e) => onChange(parseInt(e.target.value))}
                      value={value ? value : ''}
                      label="Ano de realização do exame preventivo (Papanicolaou)"
                      size="small"
                      variant="outlined"
                      error={!!errors.anamnese?.ano_papanicolaou}
                      helperText={errors.anamnese?.ano_papanicolaou?.message}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      className={styles.textField}
                    />
                  )}
                  name="anamnese.ano_papanicolaou"
                  control={control}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} lg={3.8}>
              <Alert
                severity="warning"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                }}
              >
                Última Coleta de Citopatológico de Colo Uterino em <br />
                {lastCitologia?.dt_coleta
                  ? String(
                      moment(lastCitologia?.dt_coleta).format('DD/MM/YYYY')
                    )
                  : ''}{' '}
                (Fonte: ESUS- APS)
              </Alert>
            </Grid>

            <Grid item xs={12} lg={4.3}>
              <FormControl error={errors.anamnese?.hpv && true}>
                <FormLabel sx={styleFormLabel} required>
                  Fez o teste de HPV alguma vez?
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                    >
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Sim}
                        control={<Radio sx={styleRadio} />}
                        label="Sim"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Nao}
                        control={<Radio sx={styleRadio} />}
                        label="Não"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.NaoSabe}
                        control={<Radio sx={styleRadio} />}
                        label="Não Sabe"
                      />
                    </RadioGroup>
                  )}
                  name="anamnese.hpv"
                  control={control}
                />
                {errors.anamnese?.hpv?.message && (
                  <FormHelperText>
                    {errors.anamnese?.hpv?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {rhf.anamnese?.hpv === Escolha.Sim ? (
              <Grid item sx={{ mt: 2 }} xs={12} lg={3.9}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <StyledAsteriskTextField
                      fullWidth
                      disabled={screenMode === 'view'}
                      onChange={(e) => onChange(parseInt(e.target.value))}
                      value={value ? value : ''}
                      label="Ano de realização do teste de HPV"
                      size="small"
                      variant="outlined"
                      error={!!errors.ano_hpv?.hpv}
                      helperText={errors.ano_hpv?.hpv?.message}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      className={styles.textField}
                    />
                  )}
                  name="anamnese.ano_hpv"
                  control={control}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} lg={3.8}>
              <Alert
                severity="warning"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                }}
              >
                Último teste de HPV em <br />
                {lastHpvColeta?.dt_coleta
                  ? String(
                      moment(lastHpvColeta?.dt_coleta).format('DD/MM/YYYY')
                    )
                  : ''}{' '}
                (Fonte: Jordana)
              </Alert>
            </Grid>

            <Grid item xs={12}>
              <FormControl error={errors.anamnese?.diu && true}>
                <FormLabel required sx={styleFormLabel}>
                  Usa DIU?
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                    >
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Sim}
                        control={<Radio sx={styleRadio} />}
                        label="Sim"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Nao}
                        control={<Radio sx={styleRadio} />}
                        label="Não"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.NaoSabe}
                        control={<Radio sx={styleRadio} />}
                        label="Não Sabe"
                      />
                    </RadioGroup>
                  )}
                  name="anamnese.diu"
                  control={control}
                />
                <FormHelperText>{errors.anamnese?.diu?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={errors.anamnese?.gravida && true}>
                <FormLabel
                  required
                  sx={styleFormLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Está Grávida?
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                    >
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Sim}
                        control={<Radio sx={styleRadio} />}
                        label="Sim"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Nao}
                        control={<Radio sx={styleRadio} />}
                        label="Não"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.NaoSabe}
                        control={<Radio sx={styleRadio} />}
                        label="Não Sabe"
                      />
                    </RadioGroup>
                  )}
                  name="anamnese.gravida"
                  control={control}
                />
                <FormHelperText>
                  {errors.anamnese?.gravida?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={errors.anamnese?.anticoncepcional && true}>
                <FormLabel
                  required
                  sx={styleFormLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Usa pílula anticoncepcional?
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                    >
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Sim}
                        control={<Radio sx={styleRadio} />}
                        label="Sim"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Nao}
                        control={<Radio sx={styleRadio} />}
                        label="Não"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.NaoSabe}
                        control={<Radio sx={styleRadio} />}
                        label="Não Sabe"
                      />
                    </RadioGroup>
                  )}
                  name="anamnese.anticoncepcional"
                  control={control}
                />
                <FormHelperText>
                  {errors.anamnese?.anticoncepcional?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={errors.anamnese?.radioterapia && true}>
                <FormLabel
                  required
                  sx={styleFormLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Já fez tratamento por radioterapia?
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                    >
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Sim}
                        control={<Radio sx={styleRadio} />}
                        label="Sim"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Nao}
                        control={<Radio sx={styleRadio} />}
                        label="Não"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.NaoSabe}
                        control={<Radio sx={styleRadio} />}
                        label="Não Sabe"
                      />
                    </RadioGroup>
                  )}
                  name="anamnese.radioterapia"
                  control={control}
                />
                <FormHelperText>
                  {errors.anamnese?.radioterapia?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item container xs={12}>
              <FormControl
                style={{ width: '100%', display: 'flex' }}
                error={errors.anamnese?.dt_menstruacao && true}
              >
                <Grid item xs={12}>
                  <FormLabel
                    required={!checkedMenstruacao}
                    sx={styleFormLabel}
                    id="demo-row-radio-buttons-group-label"
                  >
                    Data da última menstruação / regra
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ptBR}
                  >
                    <div>
                      <Controller
                        control={control}
                        name="anamnese.dt_menstruacao"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <DatePicker
                              mask="__/__/____"
                              disabled={
                                checkedMenstruacao || screenMode === 'view'
                              }
                              onChange={onChange}
                              value={value ? value : null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    errors.anamnese?.dt_menstruacao &&
                                    true &&
                                    !checkedMenstruacao
                                  }
                                  style={{ marginTop: '20px' }}
                                  label="Data da última menstruação"
                                  size="small"
                                  variant="outlined"
                                  className={styles.textField}
                                  fullWidth
                                />
                              )}
                            />
                          );
                        }}
                      />
                      {checkedMenstruacao ? undefined : (
                        <FormHelperText>
                          {errors.anamnese?.dt_menstruacao?.message}
                        </FormHelperText>
                      )}
                    </div>
                  </LocalizationProvider>
                  <FormControlLabel
                    style={{ marginTop: '15px', marginLeft: '15px' }}
                    name="dt_menstruacao_desconhecida"
                    control={
                      <Checkbox
                        disabled={screenMode === 'view'}
                        name="dt_menstruacao_desconhecida"
                        checked={checkedMenstruacao}
                        onChange={handleChange}
                        sx={{
                          color: '#238884',
                          '&.Mui-checked': {
                            color: '#238884',
                          },
                        }}
                      />
                    }
                    label="Não sabe / Não lembra"
                  />
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={errors.anamnese?.sangramento_relacao && true}>
                <FormLabel
                  required
                  sx={styleFormLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Tem ou teve algum sangramento após relações sexuais?
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                    >
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Sim}
                        control={<Radio sx={styleRadio} />}
                        label="Sim"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.NaoSabe}
                        control={<Radio sx={styleRadio} />}
                        label="Não / Não Sabe / Não Lembra"
                      />
                    </RadioGroup>
                  )}
                  name="anamnese.sangramento_relacao"
                  control={control}
                />
                <FormHelperText style={{ fontStyle: 'italic' }}>
                  Não considerar a primeira relação sexual na vida
                </FormHelperText>
                <FormHelperText>
                  {errors.anamnese?.sangramento_relacao?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                error={errors.anamnese?.sangramento_menopausa && true}
              >
                <FormLabel
                  sx={styleFormLabel}
                  id="demo-row-radio-buttons-group-label"
                >
                  Está na menopausa? (Não apresenta ciclos mentruais há 12 meses
                  ou mais)
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value ? value : ''}
                      row
                    >
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Sim}
                        control={<Radio sx={styleRadio} />}
                        label="Sim"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.Nao}
                        control={<Radio sx={styleRadio} />}
                        label="Não"
                      />
                      <FormControlLabel
                        disabled={screenMode === 'view'}
                        value={Escolha.NaoSabe}
                        control={<Radio sx={styleRadio} />}
                        label="Não sabe"
                      />
                    </RadioGroup>
                  )}
                  name="anamnese.menopausa"
                  control={control}
                />
                {/* <FormHelperText style={{ fontStyle: 'italic' }}>
                        Não considerar o(s) sangramento(s)na vigência de
                        reposição hormonal
                      </FormHelperText> */}
                <FormHelperText>
                  {errors.anamnese?.sangramento_menopausa?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            {watch('anamnese.menopausa') === 'SIM' && (
              <>
                <Grid item xs={12} ml={4}>
                  <FormControl
                    error={errors.anamnese?.sangramento_menopausa && true}
                  >
                    <FormLabel
                      sx={styleFormLabel}
                      id="demo-row-radio-buttons-group-label"
                    >
                      Após a menopausa tem ou teve sangramento vaginal?
                    </FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value ? value : null}
                          row
                        >
                          <FormControlLabel
                            disabled={screenMode === 'view'}
                            value={Escolha.Sim}
                            control={<Radio sx={styleRadio} />}
                            label="Sim"
                          />
                          <FormControlLabel
                            disabled={screenMode === 'view'}
                            value={Escolha.Nao}
                            control={<Radio sx={styleRadio} />}
                            label="Não"
                          />
                          <FormControlLabel
                            disabled={screenMode === 'view'}
                            value={Escolha.NaoSabe}
                            control={<Radio sx={styleRadio} />}
                            label="Não sabe"
                          />
                        </RadioGroup>
                      )}
                      name="anamnese.sangramento_menopausa"
                      control={control}
                    />
                    {/* <FormHelperText style={{ fontStyle: 'italic' }}>
                        Não considerar o(s) sangramento(s)na vigência de
                        reposição hormonal
                      </FormHelperText> */}
                    <FormHelperText>
                      {errors.anamnese?.sangramento_menopausa?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} ml={4}>
                  <FormControl
                    error={errors.anamnese?.sangramento_menopausa && true}
                  >
                    <FormLabel
                      sx={styleFormLabel}
                      id="demo-row-radio-buttons-group-label"
                    >
                      Usa hormônio / remédio para tratar a menopausa?
                    </FormLabel>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value ? value : ''}
                          row
                        >
                          <FormControlLabel
                            disabled={screenMode === 'view'}
                            value={Escolha.Sim}
                            control={<Radio sx={styleRadio} />}
                            label="Sim"
                          />
                          <FormControlLabel
                            disabled={screenMode === 'view'}
                            value={Escolha.Nao}
                            control={<Radio sx={styleRadio} />}
                            label="Não"
                          />
                          <FormControlLabel
                            disabled={screenMode === 'view'}
                            value={Escolha.NaoSabe}
                            control={<Radio sx={styleRadio} />}
                            label="Não sabe"
                          />
                        </RadioGroup>
                      )}
                      name="anamnese.hormonio"
                      control={control}
                    />

                    <FormHelperText>
                      {errors.anamnese?.sangramento_menopausa?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default AnmamneseForm;

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { InfoFilters } from '../../../components/InfoFilters';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import ReturnButton from '../../../components/ReturnButton';
import { SelectWithSearch } from '../../../components/SelectWithSearch';
import { SideMenu } from '../../../components/SideMenu';
import { StyledAsteriskTextField } from '../../../components/StyledAsteriskTextField/styles';
import { TableReportsColposcopia } from '../../../components/TableReports/TableColposcopia/TableReportsColposcopia';
import { estados } from '../../../mocks/estados-mock';
import { laboratorios } from '../../../mocks/laboratorios-mock';
import { lugol } from '../../../mocks/lugol-mock';
import { municipios } from '../../../mocks/municipios-mock';
import { tiposAchados } from '../../../mocks/tipos-achados-mock';
import { zt } from '../../../mocks/zt-mock';
import { Option } from '../../../types/Option';
import { pessoas } from '../../../mocks/pessoas-mock';
import FiltersReportsHistopatologia from '../ReportsHistopatologia/FiltersReportsHistopatologia';
import FiltersReportsColposcopia from './FiltersReportsColposcopia';
import { DisplayFilter, ItemsDisplayFilter } from '../../Convocation/styles';

const RequiredText = () => {
  return (
    <div style={{ margin: '3px 0 0', fontSize: '12px' }}>
      <p style={{ margin: 0, fontStyle: 'italic', color: 'rgba(0,0,0,0.6)' }}>
        <span style={{ color: 'red' }}>* </span>Obrigatório
      </p>
    </div>
  );
};

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'data_ini_colposcopia', value: '' },
  { inputName: 'data_fin_colposcopia', value: '' },
  { inputName: 'macroscopico', value: '' },
  { inputName: 'aval_geral', value: '' },
  { inputName: 'visibilidade', value: '' },
  { inputName: 'zt', value: '' },
  { inputName: 'achados', value: '' },
  { inputName: 'lugol', value: '' },
  { inputName: 'schiller', value: '' },
  { inputName: 'biopsia', value: '' },
  { inputName: 'laboratorio_responsavel_laudo', value: '' },
  { inputName: 'profissional_responsavel_laudo', value: '' },
  { inputName: 'search', value: '' },
];

const alteracoes: Option[] = [
  {
    label: 'Com Alterações',
    value: 'COM_ALTERACOES',
  },
  {
    label: 'Sem Alterações',
    value: 'SEM_ALTERACOES',
  },
];

const avaliacao: Option[] = [
  {
    label: 'Adequada',
    value: 'ADEQUADA',
  },
  {
    label: 'Inadequada',
    value: 'INADEQUADA',
  },
];

const visibilidadeEscamocolunar: Option[] = [
  { label: 'Completamente Visivel', value: 'COMPLETAMENTE_VISIVEL' },
  { label: 'Nao Visivel', value: 'NAO_VISIVEL' },
  { label: 'Parcialmente Visivel', value: 'PARCIALMENTE_VISIVEL' },
];

const testeSchiller: Option[] = [
  { label: 'Negativo', value: 'NEGATIVO' },
  { label: 'Positivo', value: 'POSITIVO' },
];

const realizarBiopsia: Option[] = [
  { label: 'Sim', value: 'SIM' },
  { label: 'Não', value: 'NAO' },
];

export const ReportsColposcopia = () => {
  const [tableData, setTableData] = useState({
    data: [],
    page: 1,
    count: 25,
    totalPages: 1,
    totalItems: 1,
  });
  const [profissional, setProfissional] = useState('');
  const [disableOtherInputs, setDisableOtherInputs] = useState('');
  const [, setEstadosSelecionados] = useState<Option[]>([]);
  const [, setMunicipiosSelecionados] = useState<Option[]>([]);
  const [, setLaboratoriosSelecionados] = useState<Option[]>([]);
  const [period, setTipePeriod] = useState('date');

  const [periodDtColposcopia, setPeriodDtColposcopia] = useState('period');
  const [periodDtResultado, setPeriodDtResultado] = useState('period');

  const [showTableData, setShowTableData] = useState(false);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [selectedResultado, setSelectedResultado] = useState();
  const [dtColposcopiaIni, setDtColposcopiaIni] = useState<any>(null);
  const [dtColposcopiaFinal, setDtColposcopiaFinal] = useState<any>(null);
  const [dtResultadoIni, setDataResultadoIni] = useState<any>(null);
  const [dtResultadoFinal, setDataResultadoFinal] = useState<any>(null);

  const [selectedOrigem, setSelectedOrigem] = useState([]);
  const [selectedCarater, setSelectedCarater] = useState([]);
  const [selectedEscamosa, setSelectedEscamosa] = useState([]);
  const [selectedGlandular, setSelectedGlandular] = useState([]);

  const [selectedOptionsAvaliacao, setSelectedOptionsAvaliacao] = useState([]);
  const [selectedOptionsVisibilidade, setSelectedOptionsVisibilidade] =
    useState([]);
  const [selectedOptionsZt, setSelectedOptionsZt] = useState([]);
  const [selectedOptionsTiposAchados, setSelectedOptionsTiposAchados] =
    useState([]);
  const [selectedOptionsLugol, setSelectedOptionsLugol] = useState([]);
  const [selectedOptionsSchiller, setSelectedOptionsSchiller] = useState([]);
  const [selectedOptionsBiopsia, setSelectedOptionsBiopsia] = useState([]);
  const [selectedOptionsAlteracoes, setSelectedOptionsAlteracoes] = useState(
    []
  );

  const [selectedMacroscopico, setSelectedMacroscopico] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });

  const clearValuesInputs = () => {
    setEstabelecimentosSelected([]);

    setEstabelecimentosSelected([]);

    setDataResultadoIni('');
    setDataResultadoFinal('');
    setDtColposcopiaFinal('');
    setDtColposcopiaIni('');
    setSelectedOrigem([]);
    setSelectedCarater([]);
    setSelectedEscamosa([]);
    setSelectedGlandular([]);
    setSelectedOptionsAvaliacao([]);
    setSelectedOptionsVisibilidade([]);
    setSelectedOptionsZt([]);
    setSelectedOptionsTiposAchados([]);
    setSelectedOptionsLugol([]);
    setSelectedOptionsSchiller([]);
    setSelectedOptionsBiopsia([]);
    setSelectedOptionsAlteracoes([]);

    setListFilter(initialStateListFilter);

    setValue('profissionalId', '');
  };

  const {
    estadosSelected,
    municipiosSelected,
    estabelecimentosSelected,
    equipesSelected,
    disableInputEstado,
    disableMunicipioInput,
    disableEstabelecimentoInput,
    setEstadosSelected,
    setMunicipiosSelected,
    setEstabelecimentosSelected,
    setDisableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    setOptionsMunicipios,
  } = useStatesFields();

  const handleChange = (event) => {
    setProfissional(event.target.value);
  };

  const handleSetSelectedOptions = (setter) => (options) => {
    setter(options);
  };

  const handleSetPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipePeriod((event.target as HTMLInputElement).value);
  };

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                Relatório Geral de Exames de Colposcopia
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography color="text.primary">Relatórios</Typography>

                <Typography key="3" color="text.primary">
                  Relatório Geral de Exames de Colposcopia
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />

            <DisplayFilter style={{ marginTop: '24px' }}>
              {!listFilterShow?.find((item) => item.value !== '') && (
                <p style={{ margin: 0, width: '413px', color: '#838383' }}>
                  Os filtros selecionados aparecerão aqui.
                </p>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {listFilterShow.map((item) => (
                  <>
                    {item.value ? (
                      <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                    ) : undefined}
                  </>
                ))}
              </div>
            </DisplayFilter>

            <FiltersReportsColposcopia
              clearErrors={clearErrors}
              errors={errors}
              setSelectedMacroscopico={setSelectedMacroscopico}
              selectedMacroscopico={selectedMacroscopico}
              disableMunicipioInput={disableMunicipioInput}
              disableEstabelecimentoInput={disableEstabelecimentoInput}
              disableInputEstado={disableInputEstado}
              clearValuesInputs={clearValuesInputs}
              selectedGlandular={selectedGlandular}
              selectedEscamosa={selectedEscamosa}
              selectedCarater={selectedCarater}
              selectedOrigem={selectedOrigem}
              setSelectedCarater={setSelectedCarater}
              setSelectedEscamosa={setSelectedEscamosa}
              setSelectedGlandular={setSelectedGlandular}
              setSelectedOrigem={setSelectedOrigem}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              setDataResultadoIni={setDataResultadoIni}
              setDataResultadoFinal={setDataResultadoFinal}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              setTableData={setTableData}
              setShowTableData={setShowTableData}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              periodDtResultado={periodDtResultado}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              setSelectedResultado={setSelectedResultado}
              setPeriodDtResultado={setPeriodDtResultado}
              selectedResultado={selectedResultado}
              watch={watch}
              setError={setError}
              showTableData={showTableData}
              listFilter={listFilter}
              setListFilter={setListFilter}
              setListFilterShow={setListFilterShow}
              // ---------------------------------------
              setDisableInputEstado={setDisableInputEstado}
              setDisabledMunicipioInput={setDisabledMunicipioInput}
              setDisabledEstabelecimentoInput={setDisabledEstabelecimentoInput}
              setSelectedOptionsAvaliacao={setSelectedOptionsAvaliacao}
              setSelectedOptionsVisibilidade={setSelectedOptionsVisibilidade}
              setSelectedOptionsZt={setSelectedOptionsZt}
              setSelectedOptionsTiposAchados={setSelectedOptionsTiposAchados}
              setSelectedOptionsLugol={setSelectedOptionsLugol}
              setSelectedOptionsSchiller={setSelectedOptionsSchiller}
              setSelectedOptionsBiopsia={setSelectedOptionsBiopsia}
              selectedOptionsAvaliacao={selectedOptionsAvaliacao}
              selectedOptionsVisibilidade={selectedOptionsVisibilidade}
              selectedOptionsZt={selectedOptionsZt}
              selectedOptionsTiposAchados={selectedOptionsTiposAchados}
              selectedOptionsLugol={selectedOptionsLugol}
              selectedOptionsSchiller={selectedOptionsSchiller}
              selectedOptionsBiopsia={selectedOptionsBiopsia}
              disableOtherInputs={disableOtherInputs}
              setDisableOtherInputs={setDisableOtherInputs}
              periodDtColposcopia={periodDtColposcopia}
              setDtColposcopiaFinal={setDtColposcopiaFinal}
              setDtColposcopiaIni={setDtColposcopiaIni}
              dtColposcopiaIni={dtColposcopiaIni}
              dtColposcopiaFinal={dtColposcopiaFinal}
              setPeriodDtColposcopia={setPeriodDtColposcopia}
              selectedOptionsAlteracoes={selectedOptionsAlteracoes}
              setSelectedOptionsAlteracoes={setSelectedOptionsAlteracoes}
            />

            <TableReportsColposcopia
              // selectedResultado={selectedResultado}
              // dtColetaFinal={dtColetaFinal}
              // dtColetaIni={dtColetaIni}
              selectedOptionsAlteracoes={selectedOptionsAlteracoes}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              showTableData={showTableData}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              dtColposcopiaIni={dtColposcopiaIni}
              dtColposcopiaFinal={dtColposcopiaFinal}
              selectedOptionsAvaliacao={selectedOptionsAvaliacao}
              selectedOptionsVisibilidade={selectedOptionsVisibilidade}
              selectedOptionsZt={selectedOptionsZt}
              selectedOptionsTiposAchados={selectedOptionsTiposAchados}
              selectedOptionsLugol={selectedOptionsLugol}
              selectedOptionsSchiller={selectedOptionsSchiller}
              selectedOptionsBiopsia={selectedOptionsBiopsia}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};

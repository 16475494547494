import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  Paper,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import api from '../../../api';
import useStatesFields from '../../../components/MultipleFilters/hooks/useStatesFields';
import ReturnButton from '../../../components/ReturnButton';
import { SideMenu } from '../../../components/SideMenu';
import { LoadingContext } from '../../../context/LoadingContext';
import { getUserToken } from '../../../lib/auth';
import { getCidades } from '../../../lib/getCidadesEstabelecimentos';
import useGetDetailsFilters from './FiltersReportsLesoesIntraepiteliais/hooks/useGetDetailsFilters';
import useRequestFilter from './FiltersReportsLesoesIntraepiteliais/hooks/useRequestFilter';
import TableHpvLesoesIntraepiteliais from '../../../components/TableReports/TableHpvLesoesIntraepiteliais/TableHpvLesoesIntraepiteliais';
import FiltersLesoesIntraepiteliais from './FiltersReportsLesoesIntraepiteliais';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento_saude', value: '' },
  { inputName: 'histo_pos', value: '' },
  { inputName: 'histo_pre', value: '' },
  { inputName: 'indicacao', value: '' },
  { inputName: 'servico_saude', value: '' },
  { inputName: 'profissional_resp', value: '' },
  { inputName: 'local_realizacao', value: '' },
  { inputName: 'exerese', value: '' },
  { inputName: 'anestesia', value: '' },
  { inputName: 'procedimento', value: '' },
  { inputName: 'dt_ini_resultado', value: '' },
  { inputName: 'dt_fin_resultado', value: '' },
  { inputName: 'search', value: '' },

  // { inputName: 'servico_saude', value: '' },
  // { inputName: 'indicacao_ezt', value: '' },
  // { inputName: 'tipo_ezt', value: '' },
  // { inputName: 'responsavel_coleta', value: '' },
  // { inputName: 'estabelecimentoLaudoId', value: '' },
  // { inputName: 'search', value: '' },
  // { inputName: 'natureza', value: '' },
  // { inputName: 'tipo_virus', value: '' },
  // { inputName: 'dt_ini_resultado', value: '' },
  // { inputName: 'dt_fin_resultado', value: '' },
];

export const ReportsLesoesIntraepiteliais = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [showTableData, setShowTableData] = useState(true);
  const [payloadFromCharts, setPayloadFromCharts] = useState<any[]>();
  const [listFilter, setListFilter] = useState<any[]>(initialStateListFilter);
  const [listFilterShow, setListFilterShow] = useState<any[]>([]);
  const [period, setPeriod] = useState('period');
  const [periodResultado, setPeriodResultado] = useState('period');
  const [estabelecimentoUser, setEstabelecimentoUser] = useState();
  const [dtColetaIni, setDataColetaIni] = useState<any>(null);
  const [dtColetaFinal, setDataColetaFinal] = useState<any>(null);
  const [dtResultadoIni, setDataResultadoIni] = useState<any>(null);
  const [dtResultadoFinal, setDataResultadoFinal] = useState<any>(null);

  const [selectedServicos, setSelectedServicos] = useState<any>([]);
  const [selectedTipoEzt, setSelectedTipoEzt] = useState<any>([]);
  const [selectedAnestesia, setSelectedAnestesia] = useState<any>([]);
  const [selectedLocal, setSelectedLocal] = useState<any>([]);
  const [selectedEzt, setSelectedEzt] = useState<any>([]);
  const [selectedTipoProcedimento, setSelectedTipoProcedimento] = useState<any>(
    []
  );
  const [selectedHasHistoPos, setSelectedHasHistoPos] = useState<any>([]);
  const [selectedHasHistoPre, setSelectedHasHistoPre] = useState<any>([]);

  const [disabledServicos, setDisabledServicos] = useState(true);
  const location = useLocation();
  const origin = location?.state?.origin;
  const payload = location?.state?.payload;
  const clickedCategory = location?.state?.clickedCategory;
  const clickedSeries = location?.state?.clickedSeries;
  const tableOrigin = location?.state?.table;
  const userToken = getUserToken();

  const permissaoId = getUserToken().permissao_atual?.id;
  const [servicoSaude, setServicoSaude] = useState('');

  const handleSetPeriod = (e) => {
    setPeriod(e.target.value);
    setDataColetaFinal(null);
    setDataColetaIni(null);

    // setListFilterShow((prev) => {
    //   return prev.filter((field) => field.inputName !== 'dt_coleta_inicio');
    // });
    // setListFilterShow((prev) => {
    //   return prev.filter((field) => field.inputName !== 'dt_coleta_fim');
    // });
  };

  const handleChangeServicoSaude = (event: SelectChangeEvent) => {
    setServicoSaude(event.target.value as string);
  };

  const isHospital = userToken.permissao_atual.contexto === 'HOSPITAL/UPAE';

  const isPadrao =
    permissaoId === 1 ||
    permissaoId === 9 ||
    permissaoId === 10 ||
    permissaoId === 7;

  const isMunicipal = userToken.permissao_atual?.id === 3;
  const isEstadual = userToken.permissao_atual?.id === 4;
  const {
    estadosSelected,
    setEstadosSelected,
    municipiosSelected,
    setMunicipiosSelected,
    estabelecimentosSelected,
    setEstabelecimentosSelected,
    equipesSelected,
    setDisableInputEstado,
    disableInputEstado,
    setDisabledMunicipioInput,
    setDisabledEstabelecimentoInput,
    optionsMunicipios,
    setOptionsMunicipios,
  } = useStatesFields();

  const handleSetListFilterShowFromCharts = (
    inputName: string,
    newValue: any
  ) => {
    setListFilterShow((prev) => {
      return [...prev, { inputName, value: newValue }];
    });
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      page: 1,
      size: 25,
    },
  });

  const { setLoading } = useContext(LoadingContext);

  const { loadReportsIntraepitelial } = useRequestFilter({
    setTableData,
  });

  const { getMunicipioDetails, getEstabelecimentoDetails, getAcsDetails } =
    useGetDetailsFilters();

  const isMedicoLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.id === 7;

  const isAdminLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO' &&
    userToken.permissao_atual.nome === 'Admin Estabelecimento';

  const isAdminHospital =
    userToken.permissao_atual.contexto === 'HOSPITAL/UPAE' &&
    userToken.permissao_atual.nome === 'Admin Estabelecimento';

  const isOutrosHospital =
    userToken.permissao_atual.contexto === 'HOSPITAL/UPAE' &&
    userToken.permissao_atual.nome === 'Outros';

  const isUserLaboratorio =
    userToken.permissao_atual.contexto === 'LABORATÓRIO';

  const setFiltersWhenMedicoLaboratorio = () => {
    setDisableInputEstado(true);
    // setDisabledLaboratorioResultado(true);
    // setDisabledProfissionalResultado(true);
    setShowTableData(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        // setEstabelecimentosSelected([userToken.estabelecimentoId]);

        const getProfissional = await api.get(
          `/profissionais/cbo/list?cboType=M%C3%89DICO&estabelecimentoId=${userToken.estabelecimentoId}`
        );

        const dataOffProfissional = getProfissional.data?.find(
          (prof) => prof.pessoa.id === userToken.pessoa.id
        );

        setValue('profissionalResultadoId', {
          idprofissional: dataOffProfissional?.id,
          nomeprofissional: dataOffProfissional?.pessoa?.nome,
          label: dataOffProfissional?.pessoa?.nome,
        });

        const labs = [
          {
            id: 1474,
            nome_fantasia: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
            cnes: 2712105,
            label: 'LABORATORIO DA MULHER DRA MERCES PONTES CUNHA',
          },
          {
            id: 4691,
            nome_fantasia: 'US 376 POLICLINICA SALOMAO KELNER',
            cnes: 6897029,
            label: 'US 376 POLICLINICA SALOMAO KELNER',
          },
          {
            id: 158,
            nome_fantasia: 'US 144 POLICLINICA CLEMENTINO FRAGA',
            cnes: '0000647',
            label: 'US 376 POLICLINICA SALOMAO KELNER',
          },
          {
            id: 61,
            nome_fantasia: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
            cnes: '0000779',
            label: 'US 143 LABORATORIO MUNICIPAL DO RECIFE',
          },
        ] as any;

        // setSelectedOptionsLaboratorio(
        //   labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        // );
        // setSelectedLaboratorio(
        //   labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        // );

        payload = {
          page: 1,
          size: 25,
          estados: 'PE',
          profissionalResultadoId: dataOffProfissional?.id,
          laboratorioId: userToken.estabelecimentoId,
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Serviço de Saúde da Realização: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },

          {
            inputName: 'profissional_responsavel',
            value: `Profissional Responsável: ${dataOffProfissional?.pessoa?.nome}`,
          },
        ]);

        setListFilter([
          { inputName: 'estado', value: 'Estado: PE' },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Serviço de Saúde da Realização:  ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
          {
            inputName: 'profissional_responsavel',
            value: `Profissional Responsável: ${dataOffProfissional?.pessoa?.nome}`,
          },
          { inputName: 'resultado', value: '' },
          { inputName: 'profissionalLaudo', value: '' },
          { inputName: 'data_ini', value: '' },
          { inputName: 'data_fin', value: '' },
          { inputName: 'search', value: '' },
          { inputName: 'indicacao_ezt', value: '' },
          { inputName: 'tipo_ezt', value: '' },
        ]);
        resolve(payload);
      }
    });
  };

  const isLaboratorio = userToken.permissao_atual.contexto === 'LABORATÓRIO';

  const setFiltersWhenIsPadrao = () => {
    setDisableInputEstado(true);
    // setDisabledLaboratorioResultado(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      setDisabledMunicipioInput(false);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        if (!isLaboratorio) {
          setMunicipiosSelected([municipio[0].id]);

          setEstabelecimentosSelected([userToken.estabelecimentoId]);

          setListFilterShow([
            {
              inputName: 'estado',
              value: 'Estado: PE',
            },
            {
              inputName: 'municipio',
              value: `Municipio: ${userToken.endereco.municipio_nome}`,
            },
            {
              inputName: 'estabelecimento_saude',
              value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
            },
          ]);

          setListFilter([
            { inputName: 'estado', value: 'Estado: PE' },
            {
              inputName: 'municipio',
              value: `Municipio: ${userToken.endereco.municipio_nome}`,
            },
            {
              inputName: 'laboratorio_responsavel',
              value: '',
            },
            {
              inputName: 'estabelecimento_saude',
              value: `Estabelecimento de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
            },

            { inputName: 'histo_pos', value: '' },
            { inputName: 'histo_pre', value: '' },
            { inputName: 'indicacao', value: '' },
            { inputName: 'servico_saude', value: '' },
            { inputName: 'profissional_resp', value: '' },
            { inputName: 'local_realizacao', value: '' },
            { inputName: 'exerese', value: '' },
            { inputName: 'anestesia', value: '' },
            { inputName: 'procedimento', value: '' },
            { inputName: 'dt_ini_resultado', value: '' },
            { inputName: 'dt_fin_resultado', value: '' },
            { inputName: 'search', value: '' },
          ]);

          payload = {
            page: 1,
            size: 25,
            estado: 'PE',
            municipioId: userToken.estabelecimento_saude.endereco.municipio.id,
            estabelecimentoSaudeId: userToken.estabelecimentoId,
          };
        } else {
          setListFilterShow([
            {
              inputName: 'estado',
              value: 'Estado: PE',
            },
            {
              inputName: 'municipio',
              value: ``,
            },
            {
              inputName: 'estabelecimento_saude',
              value: ``,
            },
          ]);

          setListFilter([
            { inputName: 'estado', value: 'Estado: PE' },
            {
              inputName: 'municipio',
              value: ``,
            },
            {
              inputName: 'laboratorio_responsavel',
              value: '',
            },
            {
              inputName: 'estabelecimento_saude',
              value: ``,
            },

            { inputName: 'histo_pos', value: '' },
            { inputName: 'histo_pre', value: '' },
            { inputName: 'indicacao', value: '' },
            { inputName: 'servico_saude', value: '' },
            { inputName: 'profissional_resp', value: '' },
            { inputName: 'local_realizacao', value: '' },
            { inputName: 'exerese', value: '' },
            { inputName: 'anestesia', value: '' },
            { inputName: 'procedimento', value: '' },
            { inputName: 'dt_ini_resultado', value: '' },
            { inputName: 'dt_fin_resultado', value: '' },
            { inputName: 'search', value: '' },
          ]);
          payload = {
            page: 1,
            size: 25,
            estado: 'PE',
          };
        }

        // setSelectedOptionsLaboratorio(
        //   labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        // );
        // setSelectedLaboratorio(
        //   labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        // );

        resolve(payload);
      }
    });
  };

  const setFiltersWhenAdminOrOthersLaboratorio = () => {
    setDisableInputEstado(true);
    // setDisabledLaboratorioResultado(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      setDisabledMunicipioInput(false);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        // setEstabelecimentosSelected([userToken.estabelecimentoId]);

        setSelectedServicos([{ value: userToken.estabelecimentoId }]);

        // setSelectedOptionsLaboratorio(
        //   labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        // );
        // setSelectedLaboratorio(
        //   labs.filter((lab) => lab.id === userToken.estabelecimentoId)
        // );

        payload = {
          page: 1,
          size: 25,
          estado: 'PE',
          municipioId: userToken.estabelecimento_saude.endereco.municipio.id,
          servicoSaudeId: userToken.estabelecimentoId,
        };

        setListFilterShow([
          {
            inputName: 'estado',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Serviço de Saúde da Realização: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
        ]);

        setListFilter([
          { inputName: 'estado', value: 'Estado: PE' },
          {
            inputName: 'municipio',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'laboratorio_responsavel',
            value: `Serviço de Saúde da Realização: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
          { inputName: 'histo_pos', value: '' },
          { inputName: 'histo_pre', value: '' },
          { inputName: 'indicacao', value: '' },
          { inputName: 'servico_saude', value: '' },
          { inputName: 'profissional_resp', value: '' },
          { inputName: 'local_realizacao', value: '' },
          { inputName: 'exerese', value: '' },
          { inputName: 'anestesia', value: '' },
          { inputName: 'procedimento', value: '' },
          { inputName: 'dt_ini_resultado', value: '' },
          { inputName: 'dt_fin_resultado', value: '' },
          { inputName: 'search', value: '' },
        ]);
        resolve(payload);
      }
    });
  };

  const requestDataByUserPerfil = async () => {
    if (isAdminHospital || isOutrosHospital || isAdminLaboratorio) {
      setFiltersWhenAdminOrOthersLaboratorio().then((payload) => {
        loadReportsIntraepitelial(payload);
        setShowTableData(true);
      });
    } else if (isPadrao && !isHospital) {
      setFiltersWhenIsPadrao().then((payload) => {
        loadReportsIntraepitelial(payload);
        setShowTableData(true);
      });
    }

    return true;
  };

  useEffect(() => {
    if (estadosSelected.length === 1) {
      setDisabledMunicipioInput(false);
    } else {
      setDisabledMunicipioInput(true);
    }
  }, [estadosSelected]);

  // useEffect(() => {
  //   if (municipiosSelected.length === 1) {
  //     setDisabledEstabelecimentoInput(false);
  //   } else {
  //     setDisabledEstabelecimentoInput(true);
  //   }
  // }, [municipiosSelected]);

  // const setFiltersToRequestByTable = async () => {
  //   return new Promise(async (resolve, reject) => {
  //     const periodo_inicio = localStorage.getItem('coleta_inicio') || undefined;
  //     const periodo_fim = localStorage.getItem('coleta_fim') || undefined;
  //     let payloadToRequest: any = {
  //       page: 1,
  //       size: 25,
  //       periodo_inicio,
  //       periodo_fim,
  //     };
  //     const payloadFromLocalStorage = JSON.parse(
  //       String(localStorage.getItem('payload'))
  //     );

  //     const selectedChartIsReason =
  //       !!localStorage.getItem('reasonNotProcedure');
  //     const params =
  //       location?.state?.payload?.params ||
  //       payloadFromLocalStorage?.params ||
  //       undefined;
  //     if (params) {
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         ...params,
  //       };
  //     }
  //     if (params?.periodo_inicio) {
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         dt_coleta_inicio: params.periodo_inicio,
  //         dt_coleta_fim: params.periodo_fim,
  //       };
  //       handleSetListFilterShowFromCharts(
  //         'periodo',
  //         `Perido: De ${params.periodo_inicio} até ${params.periodo_fim}`
  //       );
  //       if (!selectedChartIsReason) {
  //         //caso tiver o procedimento chama aqui
  //         setValue('dtColetaIni', params.periodo_inicio);
  //         setDataColetaIni(
  //           moment(params.periodo_inicio, 'YYYY-MM-DD').format('DD-MM-YYYY')
  //         );
  //         setValue('dtColetaFinal', params.periodo_fim);
  //         setDataColetaFinal(
  //           moment(params.periodo_fim, 'YYYY-MM-DD').format('DD-MM-YYYY')
  //         );
  //       }
  //     }
  //     if (params?.procedimento) {
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         procedimento: params?.procedimento,
  //       };
  //       setValue('realizou_atendimento', params?.procedimento ? 'Sim' : 'Não');
  //       setValue('procedimento', params?.procedimento ? 'Sim' : 'Não');
  //       handleSetListFilterShowFromCharts(
  //         'realizou_atendimento',
  //         `Procedimento: ${params?.procedimento ? 'Sim' : 'Não'}`
  //       );
  //     }

  //     const selectedChartIsResult = !!localStorage.getItem('result');
  //     const selectedChartIsTypeVirus = !!localStorage.getItem('typeVirus');
  //     const selectedChartOriginCollection =
  //       localStorage.getItem('collectionOrigin');
  //     const selectedChartTotalTests =
  //       location?.state?.panelClicked === 'totalTests';

  //     if (selectedChartTotalTests) {
  //       setPeriod(period);

  //       const monthsNumber = {
  //         JAN: '01',
  //         FEV: '02',
  //         MAR: '03',
  //         ABR: '04',
  //         MAI: '05',
  //         JUN: '06',
  //         JUL: '07',
  //         AGO: '08',
  //         SET: '09',
  //         OUT: '10',
  //         NOV: '11',
  //         DEZ: '12',
  //       };

  //       const daysInMonth = {
  //         JAN: '31',
  //         FEV: '28',
  //         MAR: '31',
  //         ABR: '30',
  //         MAI: '31',
  //         JUN: '30',
  //         JUL: '31',
  //         AGO: '31',
  //         SET: '30',
  //         OUT: '31',
  //         NOV: '30',
  //         DEZ: '31',
  //       };
  //       const dataIniValue = `01-${monthsNumber[clickedCategory]}-${location.state.year}`;
  //       const dataFimValue = `${daysInMonth[clickedCategory]}-${monthsNumber[clickedCategory]}-${location.state.year}`;
  //       const dataIniSplit = dataIniValue.split('-');
  //       const dataFimSplit = dataFimValue.split('-');

  //       const dataIniPayload = `${dataIniSplit[2]}-${dataIniSplit[1]}-${dataIniSplit[0]}`;
  //       const dataFimPayload = `${dataFimSplit[2]}-${dataFimSplit[1]}-${dataFimSplit[0]}`;
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         dt_coleta_inicio: dataIniPayload,
  //         dt_coleta_fim: dataFimPayload,
  //       };
  //       setDataColetaIni(dataIniValue);
  //       setDataColetaFinal(dataFimValue);
  //     }

  //     if (location?.state?.table === 'faixa') {
  //       const dates = String(location.state.faixa?.[0])
  //         ?.split('a')
  //         ?.map((age) => age.trim());
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         idade_inicio: dates[0] || undefined,
  //         idade_final: dates[1] || undefined,
  //       };

  //       setValue('idade_ini', dates[0]);
  //       dates[1] && setValue('idade_fin', dates[1]);

  //       handleSetListFilterShowFromCharts(
  //         'idade_ini',
  //         `Idade Início: ${dates[0]}`
  //       );
  //       dates[1] &&
  //         handleSetListFilterShowFromCharts(
  //           'idade_fin',
  //           `Idade Fim: ${dates[1]}`
  //         );
  //     }

  //     const filteredByState =
  //       !!payload?.params?.estados ||
  //       !!payloadFromLocalStorage?.params?.estados;

  //     const filteredByMunicipio =
  //       !!payload?.params?.municipios ||
  //       !!payloadFromLocalStorage?.params?.municipios;

  //     const filteredByEstabelecimento =
  //       !!payload?.params?.estabelecimentos ||
  //       !!payloadFromLocalStorage?.params?.estabelecimentos;

  //     const filteredByEquipe =
  //       !!payload?.params?.equipe || !!payloadFromLocalStorage?.params?.equipe;

  //     const setDisplayFilterEstado = () => {
  //       handleSetListFilterShowFromCharts('estado', `Estado: PE`);
  //     };

  //     const setDisplayFilterMunicipio = async (valueMunicipio) => {
  //       const idsMunicipioFromCharts = String(valueMunicipio).split(',');
  //       const response = await api.get(`/municipios`, {
  //         params: {
  //           sigla: 'PE',
  //         },
  //       });

  //       let arrOffMunicipiosLabel: any = [];

  //       idsMunicipioFromCharts.forEach((idFromChart) => {
  //         const label = response.data.find(
  //           (municipio) => String(municipio.id) === String(idFromChart).trim()
  //         )?.nome;

  //         arrOffMunicipiosLabel.push(label);
  //       });

  //       const labelsToSetFilter = arrOffMunicipiosLabel;

  //       if (labelsToSetFilter.length > 1) {
  //         handleSetListFilterShowFromCharts('municipio', 'Municípios: Vários');
  //       } else {
  //         handleSetListFilterShowFromCharts(
  //           'municipio',
  //           `Município: ${labelsToSetFilter[0]}`
  //         );
  //       }
  //     };

  //     const setDisplayFilterEstabelecimentos = async ({
  //       valueEstabelecimentos,
  //       valueMunicipio,
  //     }) => {
  //       const idsEstabelecimentosFromCharts = String(
  //         valueEstabelecimentos
  //       ).split(',');
  //       const response = await api.get(`/estabelecimentos-saude`, {
  //         params: {
  //           estado: 'PE',
  //           municipio: valueMunicipio,
  //         },
  //       });

  //       let arrOffEstabelecimentosLabel: any = [];

  //       idsEstabelecimentosFromCharts.forEach((idFromChart) => {
  //         const label = response.data.data.find(
  //           (estabelecimento) =>
  //             String(estabelecimento.id) === String(idFromChart).trim()
  //         )?.nome_fantasia;

  //         arrOffEstabelecimentosLabel.push(label);
  //       });

  //       const labelsToSetFilter = arrOffEstabelecimentosLabel;

  //       if (labelsToSetFilter.length > 1) {
  //         handleSetListFilterShowFromCharts(
  //           'estabelecimento_saude',
  //           'Estabelecimentos: Vários'
  //         );
  //       } else {
  //         handleSetListFilterShowFromCharts(
  //           'estabelecimento_saude',
  //           `Estabelecimento: ${labelsToSetFilter[0]}`
  //         );
  //       }
  //     };

  //     /*if (  // isso ta bugando as regras abaixo...
  //       !filteredByState &&
  //       !filteredByMunicipio &&
  //       !filteredByEstabelecimento
  //     ) {
  //       resolve(payloadToRequest);
  //     }*/

  //     if (
  //       filteredByState &&
  //       !filteredByMunicipio &&
  //       !filteredByEstabelecimento
  //     ) {
  //       const payloadEstado = payloadFromLocalStorage
  //         ? payloadFromLocalStorage.params?.estados
  //         : payload.params?.estados;

  //       payloadToRequest = {
  //         ...payloadToRequest,

  //         estado:
  //           isPadrao || isMunicipal || isEstadual
  //             ? 'PE'
  //             : JSON.parse(payloadEstado).join(', '),
  //       };
  //       setPayloadFromCharts(payloadToRequest);
  //       setDisplayFilterEstado();
  //       setEstadosSelected(JSON.parse(payloadEstado));
  //     }

  //     // Quando há somente filtro por estado e municipio aplicado ao gráfico

  //     if (
  //       filteredByState &&
  //       filteredByMunicipio &&
  //       !filteredByEstabelecimento
  //     ) {
  //       const valueEstado =
  //         isPadrao || isEstadual || isMunicipal
  //           ? 'PE'
  //           : payloadFromLocalStorage
  //           ? JSON.parse(payloadFromLocalStorage.params?.estados).join(', ')
  //           : JSON.parse(payload.params?.estados).join(', ');

  //       const valueMunicipio =
  //         isPadrao || isMunicipal
  //           ? userToken.endereco.municipio_id
  //           : payloadFromLocalStorage
  //           ? payloadFromLocalStorage.params?.municipios
  //           : payload.params?.municipios;

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId: valueMunicipio,
  //         estado: valueEstado,
  //       };
  //       setEstadosSelected([valueEstado]);
  //       setMunicipiosSelected(
  //         String(valueMunicipio)
  //           .split(',')
  //           .map((id) => id.trim())
  //       );

  //       setPayloadFromCharts(payloadToRequest);
  //       setDisplayFilterEstado();
  //       await setDisplayFilterMunicipio(valueMunicipio);
  //     }

  //     if (filteredByState && filteredByMunicipio && filteredByEstabelecimento) {
  //       // const acsData = await getAcsDetails(clickedCategory);
  //       const valueEstado =
  //         isPadrao || isMunicipal || isEstadual
  //           ? 'PE'
  //           : payloadFromLocalStorage
  //           ? JSON.parse(payloadFromLocalStorage.params?.estados).join(', ')
  //           : JSON.parse(payload.params?.estados).join(', ');

  //       const valueMunicipio =
  //         isPadrao || isMunicipal || isEstadual
  //           ? userToken.endereco.municipio_id
  //           : payloadFromLocalStorage
  //           ? payloadFromLocalStorage.params?.municipios
  //           : payload.params?.municipios;

  //       const valueEstabelecimentos = isPadrao
  //         ? userToken.estabelecimentoId
  //         : payloadFromLocalStorage
  //         ? String(payloadFromLocalStorage.params?.estabelecimentos)
  //         : String(payload.params?.estabelecimentos);

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId: valueMunicipio,
  //         estado: valueEstado,
  //         estabelecimentoSaudeId: valueEstabelecimentos,
  //         // profissionalAcsId: acsData.data[0].id,
  //       };

  //       const nomeMunicipio = (await api.get(`/municipios/${valueMunicipio}`))
  //         .data.nome;

  //       setEstadosSelected([valueEstado]);

  //       setMunicipiosSelected(
  //         String(valueMunicipio)
  //           .split(',')
  //           .map((id) => id.trim())
  //       );
  //       setEstabelecimentosSelected(
  //         String(valueEstabelecimentos)
  //           .split(',')
  //           .map((id) => Number(id.trim()))
  //       );
  //       setDisplayFilterEstado();
  //       await setDisplayFilterMunicipio(valueMunicipio);
  //       await setDisplayFilterEstabelecimentos({
  //         valueEstabelecimentos,
  //         valueMunicipio: nomeMunicipio,
  //       });
  //       setPayloadFromCharts(payloadToRequest);
  //     }

  //     if (filteredByEquipe) {
  //       const valueEstado =
  //         isPadrao || isMunicipal || isEstadual
  //           ? 'PE'
  //           : payloadFromLocalStorage
  //           ? JSON.parse(payloadFromLocalStorage.params?.estados).join(', ')
  //           : JSON.parse(payload.params?.estados).join(', ');

  //       const valueMunicipio =
  //         isPadrao || isMunicipal || isEstadual
  //           ? userToken.endereco.municipio_id
  //           : payloadFromLocalStorage
  //           ? payloadFromLocalStorage.params?.municipios
  //           : payload.params?.municipios;

  //       const valueEstabelecimentos = isPadrao
  //         ? userToken.estabelecimentoId
  //         : payloadFromLocalStorage
  //         ? String(payloadFromLocalStorage.params?.estabelecimentos)
  //         : String(payload.params?.estabelecimentos);

  //       const valueEquipe = payloadFromLocalStorage
  //         ? payloadFromLocalStorage.params?.equipe
  //         : payload.params?.equipe;

  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         municipioId: valueMunicipio,
  //         estado: valueEstado,
  //         estabelecimentoSaudeId: valueEstabelecimentos,
  //         equipes: valueEquipe,
  //       };

  //       const nomeMunicipio = (await api.get(`/municipios/${valueMunicipio}`))
  //         .data.nome;

  //       setDisplayFilterEstado();
  //       await setDisplayFilterMunicipio(valueMunicipio);
  //       await setDisplayFilterEstabelecimentos({
  //         valueEstabelecimentos,
  //         valueMunicipio: nomeMunicipio,
  //       });
  //       setPayloadFromCharts(payloadToRequest);
  //     }

  //     if (selectedChartIsResult) {
  //       const value = localStorage.getItem('result');
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         resultado: value,
  //       };

  //       setValue(
  //         'resultado',
  //         optionsResultadoHPV.find((item) => item.value === value)
  //       );

  //       handleSetListFilterShowFromCharts(
  //         'resultado',
  //         `Resultado: ${
  //           optionsResultadoHPV.find((item) => item.value === value)?.label
  //         }`
  //       );

  //       setLoading(false);
  //     }

  //     if (selectedChartIsReason) {
  //       const value = localStorage.getItem('reasonNotProcedure');
  //       setValue('dt_criado_inicio', payloadToRequest.dt_coleta_inicio);
  //       setValue('dt_criado_fim', payloadToRequest.dt_coleta_fim);
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         atendimento_status: value,
  //         procedimento: 'false',
  //         dt_coleta_inicio: undefined,
  //         dt_coleta_fim: undefined,
  //         dt_criado_inicio: payloadToRequest.dt_coleta_inicio,
  //         dt_criado_fim: payloadToRequest.dt_coleta_fim,
  //       };
  //       setValue('dtColetaIni', undefined);
  //       setValue('dtColetaFinal', undefined);
  //       setValue('atendimento_status', value);
  //       setValue('realizou_atendimento', 'Não');

  //       handleSetListFilterShowFromCharts(
  //         'realizou_atendimento',
  //         'Realizou o atendimento?: Não'
  //       );
  //       handleSetListFilterShowFromCharts(
  //         'atendimento_status',
  //         `Motivo do Não Atendimento: ${
  //           optionsMotivoNaoAtendida.find((item) => item.value === value)?.label
  //         }`
  //       );

  //       setLoading(false);
  //     }

  //     if (selectedChartOriginCollection) {
  //       const value = localStorage.getItem('collectionOrigin');
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         natureza: value,
  //       };

  //       setValue('natureza', value);

  //       handleSetListFilterShowFromCharts(
  //         'natureza',
  //         value === 'autocoleta'
  //           ? 'Método de Coleta: Autocoleta'
  //           : 'Método de Coleta: Profissional de Saúde'
  //       );

  //       setLoading(false);
  //     }

  //     if (selectedChartIsTypeVirus) {
  //       const value = localStorage.getItem('typeVirus');
  //       payloadToRequest = {
  //         ...payloadToRequest,
  //         tipo_virus: value,
  //         resultado: 'POSITIVO',
  //       };

  //       setValue(
  //         'resultado',
  //         optionsResultadoHPV.find((item) => item.value === 'POSITIVO')
  //       );
  //       setValue(
  //         'tipo_virus',
  //         optionsTiposVirus.find((item) => item.value === value)
  //       );

  //       handleSetListFilterShowFromCharts('resultado', 'Resultado: Detectável');
  //       handleSetListFilterShowFromCharts(
  //         'tipo_virus',
  //         `Tipo de Vírus: ${
  //           optionsTiposVirus.find((item) => item.value === value)?.label
  //         }`
  //       );

  //       setLoading(false);
  //     }
  //     resolve(payloadToRequest);
  //   });
  // };

  const setFiltersWhenPadrao = async (userToken) => {
    setShowTableData(true);
    setDisableInputEstado(true);

    setDisabledMunicipioInput(true);
    setDisabledEstabelecimentoInput(true);
    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const optionsCidades = await getCidades('PE');

      if (optionsCidades.length > 0) {
        setOptionsMunicipios(optionsCidades);
        const municipio = await getMunicipioDetails(
          userToken.endereco.municipio_nome
        );

        setMunicipiosSelected([municipio[0].id]);

        setEstabelecimentosSelected([userToken.estabelecimentoId]);
        payload = {
          page: 1,
          size: 25,
          estados: 'PE',
          municipioId: municipio[0].id,
          servicoSaudeId: userToken.estabelecimentoId,
        };

        setListFilterShow([
          {
            inputName: 'estados',
            value: 'Estado: PE',
          },
          {
            inputName: 'municipios',
            value: `Municipio: ${userToken.endereco.municipio_nome}`,
          },
          {
            inputName: 'servico_saude',
            value: `Serviço de Saúde: ${userToken.estabelecimento_saude.nome_fantasia}`,
          },
        ]);
        resolve(payload);
      }
    });
  };

  const setFiltersWhenMunicipal = async (userToken) => {
    setShowTableData(false);
    setDisableInputEstado(true);
    setDisabledMunicipioInput(true);

    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      const municipio = await getMunicipioDetails(
        userToken.endereco.municipio_nome
      );

      setMunicipiosSelected([municipio[0].id]);

      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
        municipios: municipio[0].id,
      };

      setListFilterShow([
        {
          inputName: 'estados',
          value: 'Estado: PE',
        },
        {
          inputName: 'municipios',
          value: `Municipio: ${userToken.endereco.municipio_nome}`,
        },
      ]);

      resolve(payload);
    });
  };

  const setFiltersWhenEstadual = async (userToken) => {
    setShowTableData(false);
    setDisableInputEstado(true);

    return new Promise(async (resolve, reject) => {
      let payload = {};
      setEstadosSelected(['PE']);

      payload = {
        page: 1,
        size: 25,
        estados: 'PE',
      };

      setListFilterShow([
        {
          inputName: 'estados',
          value: 'Estado: PE',
        },
      ]);

      resolve(payload);
    });
  };

  // const requestDataByUserPerfil = async () => {
  //   let payloadToReturn;
  //   if (userToken.permissao_atual.id === 1) {
  //     setFiltersWhenPadrao(userToken).then((payload) => {
  //       payloadToReturn = payload;
  //       loadReportsIntraepitelial(payload);
  //     });
  //   }

  //   if (userToken.permissao_atual.id === 3) {
  //     setFiltersWhenMunicipal(userToken).then((payload) => {
  //       payloadToReturn = payload;
  //       setLoading(false);
  //     });
  //   }

  //   if (userToken.permissao_atual.id === 4) {
  //     setFiltersWhenEstadual(userToken).then((payload) => {
  //       payloadToReturn = payload;
  //       setLoading(false);
  //     });
  //   }
  //   if (userToken.permissao_atual.id === 2) {
  //     setLoading(false);
  //   }

  //   return payloadToReturn;
  // };

  // useEffect(() => {
  //   if (origin === 'tables' || !!localStorage.getItem('requestFromTable')) {
  //     setLoading(true);
  //     setFiltersToRequestByTable().then((payloadToRequest) => {
  //       setShowTableData(true);
  //       loadReportsIntraepitelial(payloadToRequest);
  //     });
  //     window.history.replaceState({}, '');
  //     localStorage.removeItem('requestFromTable');
  //     localStorage.removeItem('payload');
  //   } else {
  //     setLoading(true);
  //     requestDataByUserPerfil();
  //   }
  // }, [origin]);

  useEffect(() => {
    requestDataByUserPerfil();
  }, []);

  return (
    <SideMenu>
      <Container>
        <Box mt={12}>
          <ReturnButton backPage="/home" />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h4" fontWeight="bold">
                RRelatório Geral de Tratamentos das Lesões Intraepiteliais
              </Typography>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{ marginBottom: '16px', marginTop: '16px' }}
                sx={{ marginBottom: '24px' }}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#646464',
                  }}
                  to="/home"
                >
                  Home
                </Link>
                <Typography color="text.primary">Relatórios</Typography>

                <Typography key="3" color="text.primary">
                  RRelatório Geral de Tratamentos das Lesões Intraepiteliais
                </Typography>
              </Breadcrumbs>
            </div>
          </div>

          <Divider />
        </Box>

        <Box mt={5}>
          <Paper variant="outlined" sx={{ borderRadius: '10px', padding: 4 }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              style={{ marginBottom: '30px', fontSize: '24px' }}
            >
              Filtros
            </Typography>
            <Divider />

            {/* <InfoFilters /> */}

            <FiltersLesoesIntraepiteliais
              isPadrao={isPadrao}
              isHospital={isHospital}
              selectedHasHistoPos={selectedHasHistoPos}
              setSelectedHasHistoPos={setSelectedHasHistoPos}
              selectedHasHistoPre={selectedHasHistoPre}
              setSelectedHasHistoPre={setSelectedHasHistoPre}
              selectedTipoProcedimento={selectedTipoProcedimento}
              setSelectedTipoProcedimento={setSelectedTipoProcedimento}
              selectedEzt={selectedEzt}
              setSelectedEzt={setSelectedEzt}
              selectedLocal={selectedLocal}
              setSelectedLocal={setSelectedLocal}
              selectedAnestesia={selectedAnestesia}
              setSelectedAnestesia={setSelectedAnestesia}
              selectedTipoEzt={selectedTipoEzt}
              setSelectedTipoEzt={setSelectedTipoEzt}
              isAdminLaboratorio={isAdminLaboratorio}
              isOutrosHospital={isOutrosHospital}
              isAdminHospital={isAdminHospital}
              setDisabledServicos={setDisabledServicos}
              disabledServicos={disabledServicos}
              selectedServicos={selectedServicos}
              setSelectedServicos={setSelectedServicos}
              setTableData={setTableData}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              watch={watch}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
              estadosSelected={estadosSelected}
              setEstadosSelected={setEstadosSelected}
              municipiosSelected={municipiosSelected}
              setMunicipiosSelected={setMunicipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              setEstabelecimentosSelected={setEstabelecimentosSelected}
              setShowTableData={setShowTableData}
              showTableData={showTableData}
              setListFilter={setListFilter}
              listFilter={listFilter}
              initialStateListFilter={initialStateListFilter}
              listFilterShow={listFilterShow}
              setListFilterShow={setListFilterShow}
              period={period}
              periodResultado={periodResultado}
              setPeriodResultado={setPeriodResultado}
              setPeriod={setPeriod}
              disableInputEstado={disableInputEstado}
              setListFilterToShow={setListFilterShow}
              dtColetaIni={dtColetaIni}
              setDataColetaIni={setDataColetaIni}
              dtColetaFinal={dtColetaFinal}
              setDataColetaFinal={setDataColetaFinal}
              dtResultadoIni={dtResultadoIni}
              setDataResultadoIni={setDataResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              setDataResultadoFinal={setDataResultadoFinal}
            />

            <TableHpvLesoesIntraepiteliais
              selectedHasHistoPos={selectedHasHistoPos}
              setSelectedHasHistoPos={setSelectedHasHistoPos}
              selectedHasHistoPre={selectedHasHistoPre}
              setSelectedHasHistoPre={setSelectedHasHistoPre}
              selectedServicos={selectedServicos}
              selectedTipoProcedimento={selectedTipoProcedimento}
              selectedEzt={selectedEzt}
              selectedLocal={selectedLocal}
              selectedAnestesia={selectedAnestesia}
              selectedTipoEzt={selectedTipoEzt}
              dtResultadoIni={dtResultadoIni}
              dtResultadoFinal={dtResultadoFinal}
              tableData={tableData}
              setValue={setValue}
              watch={watch}
              setTableData={setTableData}
              estadosSelected={estadosSelected}
              municipiosSelected={municipiosSelected}
              estabelecimentosSelected={estabelecimentosSelected}
              equipesSelected={equipesSelected}
              showTableData={showTableData}
              dtColetaFinal={dtColetaFinal}
              dtColetaIni={dtColetaIni}
            />
          </Paper>
        </Box>
      </Container>
    </SideMenu>
  );
};

export default ReportsLesoesIntraepiteliais;
